import Config from '@/services/config.service.js'
const env = new Config().getConfig()

console.log({ env })
class User {
  constructor(id, address, inviter, partners, planes, regtime) {
    this.id = id
    this.partnersCount = partners
    this.plans = planes
    this.address = address
    this.inviterAddress = inviter
    this.regtime = regtime
  }
}
export const sources = {
  namespaced: true,
  state: {
    address: env.smartInfinity,
    instance: null,
    users: [],
  },
  mutations: {
    setInstance(state, value) {
      state.instance = value
    },
    updateUser(state, user) {
      if (!state.users.find((item) => item.id === user.id))
        state.users.push(user)
    },
  },
  actions: {
    async initSmartContract({ state, commit, rootGetters }) {
      try {
        if (!state.instance) {
          // iniciar contrato
          await commit(
            'setInstance',
            await rootGetters['wallet/getTronWeb'].contract().at(state.address)
          )
        }
      } catch (e) {
        console.log('error', e)
        await commit('setInstance', null)
      }
    },
    /***/
    async getUser({ state, commit, rootGetters }, address) {
      let user = null
      try {
        const tronWeb = rootGetters['wallet/getTronWeb']
        const contract = await tronWeb.contract(state.instance.abi, state.address)
        await contract
          .users(address)
          .call()
          .then((resp) => {
            user = new User(
              tronWeb.toDecimal(resp.id._hex),
              address,
              tronWeb.address.fromHex(resp.inviter),
              tronWeb.toDecimal(resp.partnersCount._hex),
              tronWeb.toDecimal(resp.plan._hex),
              tronWeb.toDecimal(resp.regTime._hex)
            )
            console.log({ resp, user })
            // verificar si el usuario esta registrado
            if (user.id !== 0) {
              commit('updateUser', user)
            }
          })
          .catch((error) => {
            console.log({ error })
          })
      } catch (e) {
        user = undefined
      }
      return Promise.resolve({ user })
    },
    /***/
    async getUserById({ state, /*getters,*/ commit, rootGetters }, id) {
      console.log({ id })
      let user = null
      try {
        const tronWeb = rootGetters['wallet/getTronWeb']
        const contract = await tronWeb.contract(state.instance.abi, state.address)
        await contract
          .usersList(id)
          .call()
          .then((resp) => {
            console.log({ resp })
            user = new User(
              id,
              tronWeb.address.fromHex(resp.addr),
              '',
              -1,
              tronWeb.toDecimal(resp.plan._hex),
              tronWeb.toDecimal(resp.regTime._hex)
            )

            user.inviterId = tronWeb.toDecimal(resp.inviter._hex)
            // verificar si el usuario esta registrado
            if (user.regtime !== 0) {
              commit('updateUser', user)
            }
          })
          .catch((error) => {
            console.log(error)
            //return Promise.reject(error)
          })
      } catch (error) {
        console.log(error)
      }
      //}
      return Promise.resolve({ user })
    },
    /**
     *estimate energy buy globalPass
     */
    async estimateEnergyBuyGlobalPass({ state, rootGetters }, data) {
      let functionSelector = ''
      let parameter = []
      const tronWeb = rootGetters['wallet/getTronWeb']
      // new user
      console.log('1')
      if (data.address == data.issuerAddress) {
        console.log('1.1')
        if (data.globalPass == 1) {
          // register
          console.log('1.2')
          functionSelector = 'Register(uint64,uint8,address)'
          parameter = [
            { type: 'uint64', value: data.sponsorID }, // inviter
            { type: 'uint8', value: data.globalPass }, // plan
            { type: 'address', value: data.coinAddress }, // coin
          ]
        } else {
          functionSelector = 'next(address)'
          parameter = [
            { type: 'address', value: data.coinAddress }, // coin
          ]
        }
      } else {
        console.log('2')
        if (data.globalPass == 1) {
          // register
          console.log('3')
          functionSelector = 'RegisterFrom(address,uint64,uint8,address)'
          parameter = [
            { type: 'address', value: data.address }, // user
            { type: 'uint64', value: data.sponsorID }, // inviter
            { type: 'uint8', value: data.globalPass }, // plan
            { type: 'address', value: data.coinAddress }, // coin
          ]
        } else {
          console.log('5')
          functionSelector = 'nextFrom(address,address)'
          parameter = [
            { type: 'address', value: data.address }, // user
            { type: 'address', value: data.coinAddress }, // coin
          ]
        }
      }
      console.log({
        contractAddress: tronWeb.address.toHex(state.address),
        functionSelector: functionSelector,
        options: { ...data.options }, // copy
        parameter: parameter,
        issuerAddress: tronWeb.address.toHex(data.issuerAddress),
      })
      let estimateEnergy = {
        energy_required : 0
      }
      //try {
        //estimateEnergy = await tronWeb.transactionBuilder.estimateEnergy(
        const result = await tronWeb.transactionBuilder.triggerConstantContract(
          tronWeb.address.toHex(state.address), // contractAddress,
          functionSelector,
          { }, //{ ...data.options }, // copy
          parameter//,
          //tronWeb.address.toHex(data.issuerAddress) // this.getAddress
        )
        estimateEnergy.energy_required = result.energy_used
      //} catch (e) {
        //console.log({ e })
      //}
      return estimateEnergy
    },
    /**
     * register - new user
     * register from - new user
     * next
     * next From
     */
    async buyGlobalPass({ state, dispatch, rootGetters }, data) {
      const contract = await rootGetters['wallet/getTronWeb'].contract(state.instance.abi, state.instance.address)
      //
      let method = null
      if (data.address == data.issuerAddress) {
        if (data.globalPass == 1) {
          method = await contract.Register(
            data.sponsorID,
            data.globalPass,
            data.coinAddress
          )
        } else {
          method = await contract.next(data.coinAddress)
        }
      } else {
        if (data.globalPass == 1) {
          method = await contract.RegisterFrom(
            data.address,
            data.sponsorID,
            data.globalPass,
            data.coinAddress
          )
        } else {
          method = await contract.nextFrom(data.address, data.coinAddress)
        }
      }
      await dispatch('listen', await method.send(data.options), { root: true })
    },
  },
  getters: {
    getUser: (state) => (address) =>
      state.users.find((item) => item.address === address),
    getUserById: (state) => (id) => state.users.find((item) => item.id === id),
    getAddressSI: (state) => state.address,
  },
}
