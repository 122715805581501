import USDTIcon from '@/components/icons/coins/USDTIcon'
import USDDIcon from '@/components/icons/coins/USDDIcon'
import USDCIcon from '@/components/icons/coins/USDCIcon'

import Config from '@/services/config.service.js'
import API_Tron from '@/services/API_Tron.service.js'

const env = new Config().getConfig()
const apiTron = new API_Tron()

const coinsDefault = [
  {
    address: env.USDT.address,
    type: 'trc20',
    symbol: 'USDT',
    name: 'Tether USD',
    tokenId: '',
    decimals: 6,
    approved: 0,
    balance: 0,
    inst: undefined,
    icon: {
      component: USDTIcon,
      properties: { width: 44, height: 46 },
    },
  },
  {
    address: env.USDP.address,
    type: 'trc20',
    symbol: 'USDP',
    name: 'Pool Stable Coin',
    tokenId: '',
    decimals: 6,
    approved: 0,
    balance: 0,
    inst: undefined,
    icon: undefined,
    url: require('@/assets/usdp_icon.svg'),
  },
  {
    address: env.PISTIS.address,
    type: 'trc20',
    symbol: 'PISTIS',
    name: 'Pistis Shopia',
    tokenId: env.PISTIS.tokenId,
    mult: 1000000000000,
    decimals: 18,
    approved: 0,
    balance: 0,
    inst: undefined,
    icon: undefined,
    url: require('@/assets/pistis_icon.svg'),
  },
  {
    address: env.USDD.address,
    type: 'trc20',
    symbol: 'USDD',
    name: 'Decentralized USD',
    tokenId: '',
    decimals: 18,
    approved: 0,
    balance: 0,
    inst: undefined,
    icon: {
      component: USDDIcon,
      properties: { width: 44, height: 46 },
    },
  },
  {
    address: env.USDC.address,
    type: 'trc20',
    symbol: 'USDC',
    name: 'USD Coin',
    tokenId: '',
    decimals: 18,
    approved: 0,
    balance: 0,
    inst: undefined,
    icon: {
      component: USDCIcon,
      properties: { width: 44, height: 46 },
    },
  },
  {
    type: 'trc10',
    tokenId: '0',
    symbol: 'TRX',
    display: 'TRX',
    name: 'TRON',
    decimals: 6,
    balance: 0,
    img: undefined,
    icon: {
      component: null,
      properties: {
        width: 44,
        height: 46,
      },
    },
  },
]

export const coins = {
  namespaced: true,
  state: {
    coins: coinsDefault,
    assets: [],
    tronGrid: undefined,
  },
  mutations: {
    addBalance(state, data) {
      let asset = state.assets.find(
        (item) => item.symbol === data.symbol && item.owner === data.owner
      )
      if (asset === undefined) {
        state.assets.push({
          symbol: data.symbol,
          owner: data.owner,
          balance: data.value,
        })
      } else {
        asset.balance = data.value
      }
    },
    addAllowed(state, data) {
      let asset = state.assets.find(
        (item) => item.symbol === data.symbol && item.owner === data.owner
      )
      if (asset === undefined) {
        state.assets.push({
          symbol: data.symbol,
          owner: data.owner,
          alloweds: [
            {
              to: data.to,
              allowed: data.value,
            },
          ],
        })
      } else {
        if (asset.alloweds !== undefined) {
          let allowed = asset.alloweds.find((item) => item.to === data.to)
          if (allowed === undefined) {
            asset.alloweds = [{ to: data.to, allowed: data.value }]
          } else {
            allowed.allowed = data.value
          }
        } else {
          asset.alloweds = [{ to: data.to, allowed: data.value }]
        }
      }
    },
  },
  actions: {
    async initCoins({ state, rootGetters }, data) {
      try {
        await Promise.all(
          data.coins.map(async (symbol) => {
            let coin = state.coins.find((item) => item.symbol === symbol)
            if (
              coin &&
              !coin.inst &&
              coin.type == 'trc20' &&
              coin.address != ''
            ) {
              coin.inst = await apiTron.getContract(
                rootGetters['wallet/getTronWeb'],
                coin.address
              )
            } else {
              console.log('get contract error: ' + symbol)
            }
          })
        )
      } catch (e) {
        console.log('error: getContractAssets', { e })
      }
    },
    /* refactorizar con una consulta trc10 cargar todos los balances*/
    async setBalance({ state, commit, rootGetters }, data) {
      let balance = 0
      const coin = state.coins.find((item) => item.symbol === data.symbol)
      if (coin) {
        try {
          if (coin.type && coin.type === 'trc10') {
            const account = await apiTron.getAccountByAddress(
              rootGetters['wallet/getTronWeb'],
              data.owner
            )
            commit('addBalance', {
              symbol: 'TRX',
              owner: data.owner,
              value: account.balance,
            })
            if (
              coin.tokenId !== '' &&
              Number(coin.tokenId) !== 0 &&
              account.assetV2
            ) {
              const asset = account.assetV2.find(
                (item) => item.key === coin.tokenId
              )
              if (asset && asset.value > 0) {
                balance = asset.value
              }
              commit('addBalance', {
                symbol: data.symbol,
                owner: data.owner,
                value: balance,
              })
            }
          } else {
            balance = await apiTron.balanceOf(
              rootGetters['wallet/getTronWeb'],
              coin.inst,
              data.owner
            )
            commit('addBalance', {
              symbol: data.symbol,
              owner: data.owner,
              value: balance,
            })
          }
        } catch (error) {
          console.log({ error })
        }
      }
      return balance
    },
    async setAllowed({ state, commit, rootGetters }, data) {
      let allowed = 0
      const coin = state.coins.find((item) => item.symbol === data.symbol)
      if (coin) {
        try {
          allowed = await apiTron.allowance(
            rootGetters['wallet/getTronWeb'],
            coin.inst,
            data.owner,
            data.to
          )
          commit('addAllowed', {
            symbol: data.symbol,
            owner: data.owner,
            to: data.to,
            value: allowed,
          })
        } catch (error) {
          console.log({ error })
        }
      }
      return allowed
    },
    async approve({ state, dispatch, rootGetters }, data) {
      const coin = state.coins.find((item) => item.symbol == data.symbol)
      const txId = await apiTron.approve(
        rootGetters['wallet/getTronWeb'],
        coin.inst,
        data.options,
        data.to,
        data.amount
      )
      await dispatch('listen', txId, { root: true })
    },
    /**
     *estimate energy buy Approve
     */
    async estimateEnergyApprove({ rootGetters }, data) {
      const tronWeb = rootGetters['wallet/getTronWeb']
      let estimateEnergy = {
        energy_required : 0
      }
      const result = await tronWeb.transactionBuilder.triggerConstantContract(
        tronWeb.address.toHex(data.contractAddress), // contractAddress,
        'approve(address,uint256)', // functionSelector,
        { }, // options
        [
          { type: 'address', value: data.spender },
          { type: 'uint256', value: tronWeb.toHex(data.amount) },
        ]
      )
      estimateEnergy.energy_required = result.energy_used
      
      return estimateEnergy
    },
  },

  getters: {
    getCoinsBySymbols: (state) => (symbols) => {
      return state.coins.filter((elem) =>
        symbols.some((item) => item === elem.symbol)
      )
    },
    getBalanceDecimal: (state) => (data) => {
      let coin
      const asset = state.assets.find(
        (item) => item.symbol === data.symbol && item.owner === data.owner
      )
      let value = 0
      if (asset && asset.balance && asset.balance > 0) {
        coin = state.coins.find((item) => item.symbol === data.symbol)
        if (coin && coin.decimals && coin.decimals > 0) {
          value = Number(
            (asset.balance / Math.pow(10, coin.decimals)).toFixed(2)
          )
        }
      }
      return value
    },
    getToDecimal: (state) => (data) => {
      let v = 0
      let coin = state.coins.find((item) => item.symbol === data.symbol)
      if (coin && coin.decimals && coin.decimals > 0) {
        v = data.amount / Math.pow(10, coin.decimals)
      }
      return v
    },
  },
}
