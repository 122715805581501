<template>
  <div class="form-buy-global-pass">
    <div class="bg-1">
      <div class="d-flex" v-for="(value, key) in getInfo" :key="key">
        <h6 class="t16">{{ key }}</h6>
        <h6 class="ml-auto t16 t17">{{ value }}</h6>
      </div>
    </div>
    <div class="bg-1">
      <!--input-->
      <div>
        <b-form-group>
          <label for="" class="w-100">
            <div class="d-flex">
              <h6 class="t16 t18 mb-0">Amount</h6>
              <h6 class="t16 t18 mb-0 ml-auto">
                Balance: <span> {{ this.GLOBAL.moneyFormat(balance, 2) }}</span>
              </h6>
            </div>
          </label>
          <b-input-group class="input-dropdown">
            <b-form-input
              v-model="globalPass.amount"
              :disabled="true"
              :state="balanceValidate.isValid"
            ></b-form-input>
            <template #append>
              <DropdownComponent
                :items.sync="coins"
                :selected="selectedCoin"
                @click="selectCoin"
              />
            </template>
          </b-input-group>
          <b-form-invalid-feedback
            class="pl-2"
            :state="balanceValidate.isValid"
          >
            {{ balanceValidate.message }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="d-flex" v-for="(value, key) in getInfoCoins" :key="key">
        <h6 class="t16">{{ key }}</h6>
        <h6 class="ml-auto t16 t17">{{ value }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DropdownComponent from '@/components/DropdownComponent.vue'

export default {
  name: 'form-buy-global-pass',
  props: [
    'index',
    'address',
    'sponsor',
    'globalPass',
    'balanceValidate',
    'refresh',
  ],
  components: {
    DropdownComponent,
  },
  data() {
    return {
      buy: 'GlobalPass Bronze',
      sponsor_: {
        address: '',
        id: '',
      },
      balance: 0,
      approved: 0,
      selectedCoin: null,
      fee1: 250,
      fee2: 0,
      coins: [],
      coinsAvailable: ['USDT', 'USDP', 'PISTIS', 'USDC', 'USDD'],
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'wallet/isLoggedIn',
      getAddress: 'wallet/getAddress',
      getStatusWallet: 'wallet/getStatusWallet',
      getStatusPluginOptions: 'wallet/getStatusPluginOptions',
      getBalanceDecimal: 'coins/getBalanceDecimal',
      getCoinsBySymbols: 'coins/getCoinsBySymbols',
      getToDecimal: 'coins/getToDecimal',
      getAddressSI: 'sources/getAddressSI',
      getUserById: 'sources/getUserById',
    }),
    getInfo() {
      return {
        Buy: this.globalPass.name,
        'To address': this.GLOBAL.addressFormat(this.address),
        Sponsor: this.GLOBAL.addressFormat(this.sponsor_.address),
        SponsorID: this.GLOBAL.userID_Formart(this.sponsor_.id),
      }
    },
    getInfoCoins() {
      return {
        Approved:
          this.GLOBAL.moneyFormat(this.approved, 2) +
          ' ' +
          (this.selectedCoin ? this.selectedCoin.symbol : ''),
        Fee: this.GLOBAL.moneyFormat(this.fee2, 2) + ' SGBT',
      }
    },
  },
  methods: {
    setBalance(value) {
      this.balance = value
      this.$emit('update', {
        index: this.index,
        value: value,
        key: 'balance',
      })
    },
    setApproved(value) {
      this.approved = value
      this.$emit('update', {
        index: this.index,
        value: value,
        key: 'approved',
      })
    },
    resetBalance() {
      this.setBalance(0)
      this.setApproved(0)
    },
    async getBalance() {
      if (this.selectedCoin) {
        if (this.isLoggedIn) {
          const data = {
            symbol: this.selectedCoin.symbol,
            owner: this.getAddress,
          }
          await this.$store.dispatch('coins/setBalance', data)
          this.setBalance(this.getBalanceDecimal(data))
        }
      }
    },
    async currentAllowance() {
      const data = {
        symbol: this.selectedCoin.symbol,
        owner: this.getAddress,
        to: this.getAddressSI,
      }
      this.setApproved(
        this.getToDecimal({
          amount: await this.$store.dispatch('coins/setAllowed', {
            symbol: this.selectedCoin.symbol,
            owner: this.getAddress,
            to: this.getAddressSI,
          }),
          symbol: data.symbol,
        })
      )
    },
    coinBySymbol(symbol) {
      return this.coins.find((item) => item.symbol == symbol)
    },
    selectCoinDefault() {
      return this.coins.find((item) => !item.disabled)
    },
    async selectCoin(selected) {
      this.$emit('loading', true)
      this.resetBalance()

      if (selected) {
        this.selectedCoin = selected
      }
      // update
      if (this.selectedCoin) {
        this.selectedCoin = this.coinBySymbol(this.selectedCoin.symbol)
      }

      if (!this.selectedCoin || this.selectedCoin.disabled) {
        this.selectedCoin = this.selectCoinDefault()
      }

      await this.getBalance()
      await this.currentAllowance()

      this.$emit('update', {
        index: this.index,
        value: this.selectedCoin.symbol,
        key: 'selectCoinSymbol',
      })

      this.$emit('loading', false)
    },
    updateCoins() {
      let index = null
      let item = null
      for (let i = 0; i < this.coins.length; i++) {
        if (this.coins[i].symbol == 'USDP') {
          index = i
          item = Object.assign({}, this.coins[i])
          item.disabled =
            this.globalPass.id == 1 || this.getAddress != this.address
          break
        }
      }
      if (item) {
        this.$set(this.coins, index, item)
      }
    },
    async getFormCoins() {
      this.coins = this.getCoinsBySymbols(this.coinsAvailable)
      this.updateCoins()
      await this.selectCoin(this.selectedCoin)
    },
  },
  async created() {
    this.$emit('loading', true)
    // buscar sponsor buscar son get
    const user = this.getUserById(this.sponsor)
    this.sponsor_.id = user.id
    this.sponsor_.address = user.address
    // buscar contratos coin
    await this.$store.dispatch('coins/initCoins', {
      coins: this.coinsAvailable,
    })
    this.$emit('loading', false)

    this.getFormCoins()
  },
  mounted() {
    console.log('mounted')
  },
  updated() {
    console.log('updated')
  },
  watch: {
    async getStatusWallet(_new, old) {
      // reset
      console.log('getStatusWallet:', { old, _new })

      if (this.getStatusPluginOptions.installing == _new) {
        this.resetBalance()
      }
      // wallet installed
      if (this.getStatusPluginOptions.installed == _new) {
        this.updateCoins()
        this.selectCoin(this.selectedCoin)
      }
    },
    refresh: {
      deep: true,
      handler: function (value) {
        console.log('refresh ', { value })
        this.selectCoin(this.selectedCoin)
      },
    },
  },
}
</script>
<style scoped>
.form-buy-global-pass > div {
  padding: 15px;
  padding-bottom: 6px;
  border-radius: 4px;
  margin-bottom: 10px;
}
</style>
