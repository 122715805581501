<template>
  <b-form-group>
    <b-input-group class='mb-2'>
      <b-form-input
        v-model="computedAddress"
        :state="address.isValid"
      />
      <b-form-invalid-feedback class="pl-2" :state="address.isValid">
        {{ address.message }}
      </b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'index',
    'address',
  ],
  data () { return {} },
  async created () {
    console.log('created')
  },
  methods: {},
  computed: {
    ...mapGetters({
      getStatusWallet: 'wallet/getStatusWallet',
    }),
  computedAddress: {
        get()    { return this.address.value},
        set(val) { 
          this.$emit('update', {
            index: this.index,
            value: val,
            key: 'address'
          })
        }
      }
  },
}
</script>