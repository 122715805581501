import axios from 'axios'

export default class API_Tron {
  async getContract(tronWeb, address) {
    console.log({ tronWeb, address })
    return await tronWeb.contract().at(address)
  }
  // TRC20
  async balanceOf(tronWeb, instance, address) {
    let balance = 0
    try {
      await instance
        .balanceOf(address)
        .call()
        .then((resp) => {
          balance = tronWeb.toDecimal(resp._hex)
        })
        .catch((e) => console.log({ e }))
    } catch (e) {
      console.log({ e })
    }
    return balance
  }
  // autirizacion
  async allowance(tronWeb, instance, owner, spender) {
    let allowance = 0
    try {
      await instance
        .allowance(owner, spender)
        .call()
        .then((resp) => {
          console.log('resp', resp)
          allowance = 0
          if (resp._hex) {
            allowance = tronWeb.toDecimal(resp._hex)
          } else if (resp.remaining !== undefined) {
            allowance = tronWeb.toDecimal(resp.remaining._hex)
          }
        })
        .catch((e) => console.log({ e }))
    } catch (error) {
      console.log('error', error)
    }
    return allowance
  }
  //  full node http api
  async getEnergyPriceLast(red) {
    let energyPrice = {
      timestamp: 0,
      price: 0,
    }
    try {
      const subdomain =
        red == 'trongrid' ? 'api' : red
      await axios({
        url: 'https://' + subdomain + '.trongrid.io/wallet/getenergyprices',
        method: 'get',
      }).then((resp) => {
        console.log({ resp })
        if (resp.data.prices) {
          const last = resp.data.prices.split(',').pop().split(':')
          energyPrice.timestamp = last[0]
          energyPrice.price = last[1]
          console.log({ energyPrice })
        }
      })
    } catch (e) {
      console.log({ e })
    }
    return energyPrice
  }
  // https://apilist.tronscanapi.com/api/token/price
  async getPriceInformation(red) {
    let data = null
    const host =
      red == 'nile' ? 'nileapi.tronscan.org' : 'apilist.tronscanapi.com'
    try {
      await axios({
        url: 'https://' + host + '/api/token/price',
        method: 'get',
      }).then((resp) => {
        data = resp.data
        console.log({ data })
      })
    } catch (e) {
      console.log({ e })
    }
    return data
  }
  async getAccountResources(tronWeb, address) {
    let data = null
    try {
      data = await tronWeb.trx.getAccountResources(address)
    } catch (e) {
      data = null
      console.log({ e })
    }
    return data
  }
  async getAccountByAddress(tronWeb, address) {
    let data = null
    try {
      data = await tronWeb.trx.getAccount(address)
    } catch (e) {
      data = null
      console.log({ e })
    }
    return data
  }
  // gettransactionbyid
  async gettransactionbyid(red, hash) {
    const host =
      red == 'nile' ? 'nileapi.tronscan.org' : 'apilist.tronscanapi.com'
    return await axios({
      url: 'https://' + host + '/api/transaction-info',
      method: 'get',
      params: {
        hash: hash,
      },
    })
  }
  async approve(tronWeb, instance, options, to, amount) {
    const contract = await tronWeb.contract(instance.abi, instance.address)
    return await contract.approve(to, tronWeb.toHex(amount.toNumber()))
    .send(options)
  }
}
