<template>
  <div v-if="!!data" class="ml-2 center-vh">
    <img v-if="!!data.url" :src="data.url" alt="Kitten" style="width: 30px; height: 30px">
    <component v-else-if="!!data.icon.component" 
      :is="data.icon.component"
      v-bind= "{width: 30, height: 30 }"
    />
  </div>
</template>
<script >
export default {
  props: [
    'data'
  ],
  data(){
    return {}
  },
  methods: {}
}
</script>
<style>
</style>