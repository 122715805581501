<template>
  <b-navbar toggleable="lg" fixed="top" :class="{scrolled: visible || scrollPosition > 50 }">
    <b-navbar-brand href="#" class="ml-4 mr-0">
      <img src="../assets/logo.png" alt="Kitten" />
      <b-navbar-toggle target="nav-collapse" class="ml-2 px-1 pt-0">
        <img src="../assets/bars.png" alt="Kitten" />
      </b-navbar-toggle>
    </b-navbar-brand>

    <button
     class="btn btn-primary d-lg-none py-1 px-2 mr-4"
      @click="to(config.getDashhoardURL())"
    >
      Open APP
      <img src="../assets/arrow.png" alt="Kitten" />
    </button> 

    <b-collapse
      id="nav-collapse"
      class="px-4"
      v-model="visible"
      is-nav
    >
      <b-navbar-nav class="ml-lg-auto">
        <b-nav-item 
          class="text-primary mr-lg-4"
          @click="scrollInto('ecosystem')"
        >
          Ecosistema
        </b-nav-item>
        <b-nav-item
          class="text-primary mr-lg-4"
          @click="to('https://www.smartinfinity.vip/public/whitePaper-V2-2.pdf')"
        >
          Documentación
        </b-nav-item>
        <b-nav-item
          class="text-primary mr-lg-4"
          @click="scrollInto('subscribe')"
        >
          Sucríbete
        </b-nav-item>
        <button
          class="btn btn-primary my-auto d-none d-lg-inline-block"
          @click="to(config.getDashhoardURL())"
        >
          Open APP
          <img src="../assets/arrow.png" alt="Kitten" />
        </button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Config from '@/services/config.service.js'

export default {
  name: 'nav-bar',
  data () {
    return {
      scrollPosition: null,
      visible: false
    }
  },
  methods: {
    to: (url) => window.open(url, '_blank'),
    scrollInto: (elementId) => document.getElementById(elementId)
      .scrollIntoView({ behavior: 'smooth' }),
  },
  created() {
    this.config = new Config()
  },
  mounted() {
    const vm = this
    document.querySelector('#app').addEventListener('scroll', function (item) {
      vm.scrollPosition = item.target.scrollTop
    })
  }
}
</script>

<style scoped>
nav.scrolled {
  background: #080808e6;
}
</style>
