<script>
const addressFormat = (value) =>
  value.slice(0, 4) + ' *** ' + value.slice(value.length - 7, value.length)

const moneyFormat = function (value, decimal) {
  const pieces = parseFloat(value).toFixed(decimal).split('')
  let ii = pieces.length
  if (decimal > 0) {
    ii -= decimal +1
  }
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, ',')
  }
  return pieces.join('')
}

const userID_Formart = (value) => value.toString().padStart(4, 0)

export default {
  addressFormat,
  moneyFormat,
  userID_Formart,
}
</script>
