import axios from 'axios'

const env = {
  production: {
    red: 'https://smartinfinity.vip/api/',
  },
  development: {
    red: 'http://localhost:80/api/',
  },
  'preDev': {
    red: 'https://dev.smartinfinity.vip/api/',
  }
}

class ConcentrationService {
  async getStatusSI() {
    return axios({
      url: env[process.env.NODE_ENV].red + 'smartinfinity/status',
      method: 'GET',
      params: {
        end: null,
        start: Date.now() - 24 * 60 * 60 * 1000,
        limit: null,
      },
    })
  }
  async getDepositsInfo() {
    return axios({
      url: env[process.env.NODE_ENV].red + 'smartinfinity/deposits-info',
      method: 'GET',
      params: {
        end: null,
        start: null,
        limit: 20,
      },
    })
  }
}

export default new ConcentrationService()
