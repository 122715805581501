<template>
  <div class="input-method">
    <div
      class="mb-3"
      v-for="(item, index) in methods"
			:key="index"
    >
      <div
        class="form-control item d-flex"
        :class="[isCheck(index)? 'selected': '', isInvalid(index) ? 'is-invalid': '']"
      >
        <div class="input-selector center-vh">
          <div @click="select(index)"></div>
        </div>
        <div
          class="description"
          :class="[isCheck(index)? 'text-color-primary': 'text-white']"
        >
          <h6 class="description-title">{{ item.title }}</h6>
          <h6 class="sub-title">{{ item.description }}</h6>
        </div>
      </div>
      <b-form-invalid-feedback class="pl-2" :state="validateState (index)">
        {{ message }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal-buy',
  props: [
    'index',
    'selected',
    'isNotValid',
    'methods',
    'message'
  ],
  data() { return {} },
  methods: {
    select(index) {
      if (index != this.computedSelected)
      this.computedSelected = index
    },
    validateState (index) {
      return index == this.computedSelected ? !this.isNotValid :null
    },
  },
  computed: {
    isCheck: function () {
      const vm = this
      return (index) => vm.computedSelected == index
    },
    isInvalid: function () {
      const vm = this
      return (index) => vm.computedSelected == index ? vm.isNotValid: false
    },
    computedSelected: {
      get()    { return this.selected },
      set(val) {
        console.log({ val})
        this.$emit('update', {
          index: this.index,
          value: val,
          key: 'selected'
        })
      }
    }
  },
}
</script>

<style scoped>
.input-method .item {
  border-radius: 4px;
  padding: 8px 0 8px 0;
}
.input-method .item .input-selector {
  width: 60px;
}
.input-method .item .input-selector div:first-child {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #80808099;
}
.input-method .item.selected .input-selector div:first-child {
  width: 20px;
  height: 20px;
  background-color: #FFD233CC;
  box-shadow: 0 0 0 5px #FFD23399 !important;
}
.input-method .item .description h6 {
  font-size: 14px;
  line-height: 18px;
}
.input-method .item .description .description-title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Inter-SemiBold';
}
.input-method .item .description .sub-title {
  font-family: 'Inter-Light';
  margin-bottom: 0;
}
</style>