<template>
  <div>
    <b-form-group>
      <label for="" class="t16">
        To
      </label>
      <b-input-group class='mb-2'>
        <b-form-input
          v-model="address"
          :disabled="true"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group>
      <label for="" class="t16">
        Sponsor
      </label>
      <!--globalPass.id != 1-->
      <b-input-group class='mb-2'>
        <b-form-input
          :state="sponsor.isValid"
          v-model="computedSponsor"
          :disabled="sponsor.disabled"
        />
        <b-form-invalid-feedback class="pl-2" :state="sponsor.isValid">
          {{ sponsor.message }}
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>

    <b-form-group>
      <label for="" class="t16">
        Buy
      </label>
      <b-input-group class='mb-2'>
        <b-form-input
          v-model="globalPass.description"
          :disabled="true"
        />
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: [
    'index',
    'address',
    'sponsor',
    'globalPass',
    'disabled'
  ],
  data() {
    return {}
  },
  computed: {
    computedSponsor: {
      get()    { return this.sponsor.value},
      set(val) {
        console.log({ val })
        this.$emit('update', {
          index: this.index,
          value: val,
          key: 'sponsor'
        })
      }
    }
  }
}
</script>
