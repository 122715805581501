<template>
  <b-modal
    modal-class="modal-buy"
    id="modal-buy"
    v-model="modalBuyVisible"
    body-class="pb-0"
    header-class="center-h pt-5"
    content-class="bg-4"
    footer-class="center-h pt-0 pb-5"
    no-close-on-backdrop
    centered
  >
    <template #modal-header="{}">
      <div class="w-100 center-vh">
        <img
          src="../../assets/globalpass/gold-icon.png"
          alt="Kitten"
          class="mr-2"
        />
        <h5 class="mb-0 t1 text-color-primary">Buy GlobalPass</h5>
        <div
          class="icon_x"
          @click="$bvModal.hide('modal-buy')"
        >
          <span>x</span>
        </div>
      </div>
      <div :class="[form.loading ? 'active' : '', 'loading']">
        <div class="m-auto">
          <b-spinner class="text-color-primary" :show="true"></b-spinner>
        </div>
      </div>
    </template>
    <template #default="{}">
      <b-container>
        <b-form @submit.stop.prevent="">
          <!--stage -->
          <component
            :is="componentStage"
            v-bind="propertiesStage"
            @update="handleUpdate"
            @loading="setLoading"
          />
        </b-form>
      </b-container>
    </template>
    <template #modal-footer="{}">
      <b-button class="mb-0 mr-4 t2" @click="backStage()">Back</b-button>
      <b-button class="mb-0 t2" variant="primary" @click="nextStage()">
        Next
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import API_Tron from '@/services/API_Tron.service.js'

import AddressStage from '@/components/BuyGlobalPass/AddressStage.vue'
import GlobalPassStage from '@/components/BuyGlobalPass/GlobalPassStage.vue'
import InputMethod from '@/components/BuyGlobalPass/InputMethod.vue'
import FormBuyGlobalPass from '@/components/BuyGlobalPass/FormBuyGlobalPass.vue'

const apiTron = new API_Tron()

export default {
  name: 'modal-buy',
  components: {
    InputMethod,
    FormBuyGlobalPass,
  },
  data() {
    return {
      modalBuyVisible: false,
      existingSponsor: true,
      form: {
        loading: false,
        address: '',
        // coin select
        balance: 0,
        approved: 0,
        selectCoinSymbol: '',

        globalPass: 1,
        sponsor: '',
        sponsorID: '',
        sponsorDisabled: false,
        user: {
          globalPass: 0,
          followers: 2,
        },
        refresh: false,
      },
      address: '',
      globalPass: {
        1: { name: 'GlobalPlass Bronze', amount: 30 },
        2: { name: 'GlobalPlass Silver', amount: 60 },
        3: { name: 'GlobalPlass Gold', amount: 120 },
      },
      selectedMethods: 0,
      methods: [
        {
          title: 'Direct',
          description: 'Buy automatic',
          disabled: false,
        },
        {
          title: 'Create Request',
          description: 'Hashminer',
          disabled: true,
        },
        {
          title: 'Pool Energy',
          description: 'Hashminer',
          disabled: true,
        },
      ],
      stage: 0,
      stageComponent: [
        {
          index: 0,
          component: AddressStage,
          properties: {
            address: '',
          },
        },
        {
          index: 1,
          component: GlobalPassStage,
          properties: {},
        },
        {
          index: 2,
          component: InputMethod,
          properties: {},
        },
        {
          index: 3,
          component: FormBuyGlobalPass,
          properties: {},
        },
      ],
      errorMessages: {
        required: 'Field is required.',
        isNotAvailable: 'Is not available',
        addressInvalid: 'Address is invalid',
        existing: 'Sponsor does not exist',
        // baalnce
        noZero: 'Balance is zero',
        isNotEnough: 'Balance is not enough',
        // allowed
        approvedNoZero: 'approved amount is zero',
        approvedIsNotEnough: 'approved amount is not enough',
        // globalPass
        globalPassMax: 'Max GlobalPass',
        globalPassFollowers: 'Min two guests',
      },
      maxApprove: {
        USDT: 10000,
        USDD: 10000,
        USDC: 10000,
        PISTIS: 10000,
      },
    }
  },
  computed: {
    ...mapGetters({
      getRed: 'wallet/getRed',
      isLoggedIn: 'wallet/isLoggedIn',
      getTronWeb: 'wallet/getTronWeb',
      getAddress: 'wallet/getAddress',
      getStatusWallet: 'wallet/getStatusWallet',
      getStatusPluginOptions: 'wallet/getStatusPluginOptions',
      getAddressSI: 'sources/getAddressSI',
      registerFrom: 'sources/registerFrom',
      getCoinsBySymbols: 'coins/getCoinsBySymbols',
      getBalanceDecimal: 'coins/getBalanceDecimal',
    }),
    componentStage() {
      return this.stageComponent[this.stage].component
    },
    propertiesStage() {
      let data = {}
      if (this.stage == 0) {
        const isValid = this.validateState(this.$v.validationGroup)
        let message = ''
        if (!isValid) {
          message = !this.validateState(this.$v.form.address)
            ? this.getErrorMessage(this.$v.form.address)
            : this.getErrorMessage(this.$v.user)
        }
        data = {
          index: 0,
          address: {
            value: this.form.address,
            isValid: isValid,
            message: message,
          },
        }
      } else if (this.stage == 1) {
        data = {
          index: 1,
          address: this.form.address,
          globalPass: {
            id: this.form.globalPass,
            description: this.globalPass[this.form.globalPass].name,
          },
          sponsor: {
            disabled: this.form.sponsorDisabled,
            value: this.form.sponsor,
            isValid: this.validateState(this.$v.form.sponsor),
            message: this.getErrorMessage(this.$v.form.sponsor),
          },
        }
      } else if (this.stage == 2) {
        data = {
          index: 2,
          selected: this.selectedMethods,
          isNotValid: this.methods[this.selectedMethods].disabled,
          message: this.getErrorMessage(this.$v.selectedMethods),
          methods: this.methods,
        }
      } else if (this.stage == 3) {
        const isValid = this.validateState(this.$v.selectedCoin)
        let message = ''
        if (!isValid) {
          message = !this.validateState(this.$v.form.balance)
            ? this.getErrorMessage(this.$v.form.balance)
            : this.getErrorMessage(this.$v.form.approved)
        }
        data = {
          index: 3,
          address: this.form.address,
          globalPass: {
            id: this.form.globalPass,
            name: this.globalPass[this.form.globalPass].name,
            amount: this.globalPass[this.form.globalPass].amount,
          },
          sponsor: this.form.sponsor,
          balanceValidate: {
            isValid: isValid,
            message: message,
          },
          refresh: this.form.refresh,
        }
      }
      return data
    },
  },
  methods: {
    setLoading(active) {
      this.form.loading = active
    },
    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },
    getErrorMessage(item) {
      if (item.$error) {
        for (const key in item.$params) {
          if (item[key] === false) {
            return this.errorMessages[key]
          }
        }
      }
      return null
    },
    handleUpdate(data) {
      if (data.index == 0) {
        this.form.user.id = 0
        this.form.user.globalPass = 0
        this.form.user.followers = 2
        this.$v.user.$touch()

        this.form.address = data.value
      } else if (data.index == 1) {
        this.existingSponsor = true
        this.form.sponsor = data.value
      } else if (data.index == 2) {
        this.$v.selectedMethods.$touch()
        this.selectedMethods = data.value
      } else if (data.index == 3) {
        if (this.$v.form[data.key]) this.$v.form[data.key].$touch()

        this.form[data.key] = data.value
      }
    },
    resetform() {
      this.stage = 0
      this.existingSponsor = true
      this.form.loading = false
      this.form.address = ''

      this.form.balance = 0
      this.form.approved = 0

      this.form.globalPass = 1
      this.form.sponsor = ''
      this.form.sponsorID = ''

      this.form.user.id = 0
      this.form.user.globalPass = 0
      this.form.user.followers = 2
      this.$v.user.$touch()
    },
    async getBalanceTRX() {
      const data = {
        symbol: 'TRX',
        owner: this.getAddress,
      }
      await this.$store.dispatch('coins/setBalance', data)
      return this.getBalanceDecimal(data)
    },
    async validateBalance(estimateEnergy, energyPriceLast) {
      // balance energy
      // get energy
      const userResources = await this.$store.dispatch(
        'getResources',
        this.getAddress
      )
      // delta energy
      const deltaEnergy =
        estimateEnergy.energy_required - userResources.available
      // energy prince delta
      const priceTRX = await apiTron.getPriceInformation(this.getRed)
      let costTotal_trx = 1 / parseFloat(priceTRX.price_in_usd) //  trx -> 1USD
      if (deltaEnergy > 0) {
        costTotal_trx += (energyPriceLast.price * deltaEnergy) / 1000000 // costDeltaEnergy
      }
      // balance trx
      let balance_TRX = await this.getBalanceTRX()
      if (balance_TRX < costTotal_trx) {
        // lanzar notificacio que no bloquee el proceso
        const data = {
          type: 'warning',
          message:
            '<p class="t21 mb-0">' +
            '¡Advertencia!' +
            ' Balance TRX no es suficiente.' +
            ' Esta operacion tiene un costo aproximado de ' +
            costTotal_trx.toFixed(2) +
            ' TRX' +
            ' (' +
            this.GLOBAL.moneyFormat(estimateEnergy.energy_required, 0) +
            ' Energy)' +
            ' ¿Desea Continuar?' +
            '</p>',
          cancelTitle: 'Cancel',
          noCloseOnBackdrop: true,
        }
        const confirmation = await new Promise((resolve) => {
          this.$parent.$emit('notification', { resolve, data })
        })

        if (!confirmation) {
          throw new Error()
        }
      }
      return estimateEnergy
    },
    /**
     * reperar
     */
    async approve(symbol, value) {
      // calcular energya reuqerida
      // comfirmacion
      let confirmation = false
      if (this.form.approved < value) {
        const data = {
          type: 'warning',
          message:
            '<p class="t21 mb-0">' +
            symbol +
            ' requiere aprobacion, ¿Desea continuar?' +
            '</p>',
          cancelTitle: 'Cancel',
          noCloseOnBackdrop: true,
        }

        confirmation = await new Promise((resolve) => {
          this.$parent.$emit('notification', { resolve, data })
        })
        if (!confirmation) {
          throw new Error()
        }
      }
      // estimate Energy
      const coin = this.getCoinsBySymbols([symbol])[0]
      const tronWeb = this.getTronWeb

      var options = {
        feeLimit: 100000000,
        callValue: 0,
        tokenValue: 0,
        tokenId: 0,
      }
      // get price energy
      const energyPriceLast = await apiTron.getEnergyPriceLast(this.getRed)

      let amount = 0
      if (this.maxApprove[coin.symbol]) {
        amount = this.maxApprove[coin.symbol]
      }
      amount = tronWeb.toBigNumber(amount * Math.pow(10, coin.decimals))

      const estimateEnergy = await this.$store.dispatch(
        'coins/estimateEnergyApprove',
        {
          options,
          spender: this.getAddressSI,
          contractAddress: coin.address,
          issuerAddress: this.getAddress,
          amount: amount.toNumber(),
        }
      )

      await this.validateBalance(estimateEnergy, energyPriceLast)
      // realizar approved
      const costEstimate =
        energyPriceLast.price * estimateEnergy.energy_required

      options.feeLimit = Number((costEstimate * 1.3).toFixed(0))
      options.shouldPollResponse = false

      // crear approve
      await this.$store.dispatch('coins/approve', {
        symbol: coin.symbol,
        to: this.getAddressSI,
        amount: amount,
        options: options
      })
      const data = {
        type: 'ok',
        message:
          '<p class="t21 mb-0">' +
          'La aprobacion fue realizada correctamente...' +
          '</p>',
        noCloseOnBackdrop: true,
      }
      await new Promise((resolve) => {
        this.$parent.$emit('notification', { resolve, data })
      })
    },
    /*
      register - new user
      register from - new user
      next - 
      next From -
      si tiene mucha energia y muchos trx saltar las validaciones
    */
    async Buy() {
      // estimate Energy
      const coin = this.getCoinsBySymbols([this.form.selectCoinSymbol])[0]
      var options = {
        feeLimit: 0,
        callValue: 0,
        tokenValue: 0,
        tokenId: 0,
      }
      const estimateEnergy = await this.$store.dispatch(
        'sources/estimateEnergyBuyGlobalPass',
        {
          options,
          address: this.form.address,
          sponsorID: this.form.sponsorID,
          globalPass: this.form.globalPass,
          coinAddress: coin.address,
          issuerAddress: this.getAddress,
        }
      )

      // get price energy
      const energyPriceLast = await apiTron.getEnergyPriceLast(this.getRed)

      await this.validateBalance(estimateEnergy, energyPriceLast)
      const costEstimate =
        energyPriceLast.price * estimateEnergy.energy_required

      options.feeLimit = Number((costEstimate * 1.3).toFixed(0))
      options.shouldPollResponse = false
      console.log({ options })

      await this.$store.dispatch('sources/buyGlobalPass', {
        options,
        address: this.form.address,
        sponsorID: this.form.sponsorID,
        globalPass: this.form.globalPass,
        coinAddress: coin.address,
        issuerAddress: this.getAddress,
      })

      let user_ = {
        address: this.form.address,
        id: this.form.user.id,
        globalPass: this.globalPass[this.form.globalPass].name,
      }
      if (this.form.globalPass == 1) {
        // new user
        const user = (
          await this.$store.dispatch('sources/getUser', this.form.address)
        ).user
        user_.id = user.id
      }

      const message =
        '<p class="t21 mb-0">' +
        'Felicidades, La compra fue realizada correctamente.' +
        '<br>Direccion <b>' +
        this.GLOBAL.addressFormat(user_.address) +
        '</b>' +
        '<br>ID <b>' +
        this.GLOBAL.userID_Formart(user_.id) +
        '</b>' +
        '<br>Buy <b>' +
        user_.globalPass +
        '</b>' +
        '</p>'

      this.$parent.$emit('notification', {
        data: {
          type: 'ok',
          message,
        },
      })
    },
    /**/
    async ownerValidate() {
      if (this.isLoggedIn) {
        const userResources = await this.$store.dispatch(
          'getResources',
          this.getAddress
        )

        if (!userResources) {
          throw new Error(this.$Errors[4].replace('?', this.GLOBAL.addressFormat(this.getAddress)))
        }
      }
    },
    async nextStage() {
      console.log('error ', this.$v, { stage: this.stage })
      this.form.loading = true
      try {
        if (this.stage == 0) {
          this.form.user.id = 0
          this.form.user.globalPass = 0
          this.form.user.followers = 2

          this.form.sponsorID = ''
          this.form.sponsor = ''

          this.$v.user.$touch()

          this.$v.form.address.$touch()
          if (this.$v.form.address.$anyError) {
            throw new Error()
          }
          // valid address orner is active
          // wallet active
          await this.ownerValidate()
          // valid 'to' is active, warning
          if (this.getAddress != this.form.address) {
            const userResources = await this.$store.dispatch(
              'getResources',
              this.form.address
            )

            if (!userResources) {
              const data = {
                type: 'warning',
                message: '<p class="t21 mb-0">' + this.$Errors[4].replace('?', this.GLOBAL.addressFormat(this.form.address)) + '</p>',
              }
              await new Promise((resolve) => {
                this.$parent.$emit('notification', { resolve, data })
              })
            }
          }
          // search address in smartinfinity
          const user = (
            await this.$store.dispatch('sources/getUser', this.form.address)
          ).user

          // new user
          let sponsor = null
          if (!user || user.regtime == 0) {
            // identificar sponsor
            let hash = this.$route.params.hash
            this.form.sponsor = hash ? hash : ''
            this.form.sponsorDisabled = this.form.sponsor != ''
            this.form.globalPass = 1
          } else {
            this.form.user.id = user.id
            this.form.user.globalPass = user.plans
            this.form.user.followers = user.partnersCount
            this.$v.user.$touch()

            // max GlogalPass 3
            if (user.plans == 3) {
              const data = {
                type: 'error',
                message: '<p class="t21 mb-0">' + this.$Errors[2] + '</p>',
              }

              this.$parent.$emit('notification', { data })
              throw new Error()
            }
            // upgrade plan requiere 2 inviter
            if (user.partnersCount < 2) {
              // 0, 1
              const data = {
                type: 'error',
                message: '<p class="t21 mb-0">' + this.$Errors[3] + '</p>',
              }
              this.$parent.$emit('notification', { data })
              throw new Error()
            }
            // buscar sponsor
            sponsor = (
              await this.$store.dispatch('sources/getUser', user.inviterAddress)
            ).user

            this.form.sponsor = sponsor.id
            this.form.sponsorID = sponsor.id
            this.form.sponsorDisabled = true
            this.form.globalPass = user.plans + 1
          }
          // buscar sponsor by address or ID
        } else if (this.stage == 1) {
          this.existingSponsor = true
          this.$v.form.sponsor.$touch()
          if (this.$v.form.sponsor.$anyError) {
            throw new Error()
          }
          // validate sponsor in source
          this.form.sponsorID = this.form.sponsor
          const user = (
            await this.$store.dispatch('sources/getUserById', this.form.sponsor)
          ).user
          this.existingSponsor = !!user && user.regtime != 0

          this.$v.form.sponsor.$touch()
          if (this.$v.form.sponsor.$anyError) {
            throw new Error()
          }
        } else if (this.stage == 2) {
          this.$v.selectedMethods.$touch()
          if (this.$v.selectedMethods.$anyError) {
            throw new Error()
          }
        } else if (this.stage == 3) {
          // Buy
          // set price trx-usd
          // set price energy
          // direccion activa?
          if (!this.getTronWeb.ready) {
            const data = {
              type: 'error',
              message: '<p class="t21 mb-0">' + this.$Errors[1] + '</p>',
            }
            this.$parent.$emit('notification', { data })
          }
          this.$v.selectedCoin.$touch()
          // validar approved
          if (this.$v.form.balance.$anyError) {
            throw new Error()
          }
          // validate approved
          if (this.$v.form.approved.$anyError) {
            await this.ownerValidate()
            await this.approve(
              this.form.selectCoinSymbol,
              this.globalPass[this.form.globalPass].amount
            )
          }
          // buy
          await this.Buy()
          
          this.$emit('reStartDaemon')
          this.$bvModal.hide('modal-buy')
        }

        if (this.stage + 1 < this.stageComponent.length) {
          this.stage++
        }
        this.form.loading = false
      } catch (e) {
        console.log({ e })
        let msg
        if (e.message) {
          msg = e.message
        } else if (typeof e === 'string' || e instanceof String) {
          msg = e
        }
        if (msg) {
          const data = {
            type: 'error',
            message: '<p class="t21 mb-0">' + msg + '</p>',
          }
          this.$parent.$emit('notification', { data })
        }
        this.form.loading = false
        this.form.refresh = !this.form.refresh
      }
    },
    backStage() {
      if (this.stage == 0) this.$bvModal.hide('modal-buy')
      else this.stage--
    },
  },
  async created() {
    this.address = this.getAddress
  },
  watch: {
    async getStatusWallet(_new, old) {
      if (this.modalBuyVisible) {
        if (this.stage == 0) {
          this.$bvModal.hide('modal-buy')
        } else {
          this.form.loading = this.getStatusPluginOptions.installing == _new
        }
      }
      console.log('getStatusWallet:', { old, _new })
    },
    async getAddress(_new, old) {
      console.log('getStatusWallet:', { old, _new })
      this.address = _new ? _new : ''
    },
    modalBuyVisible: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.resetform()
          if (this.getTronWeb.ready) this.form.address = this.getAddress
        }
      },
    },
  },
  validations: {
    form: {
      address: {
        required,
        addressInvalid: function (value) {
          // reset message
          const tronWeb = this.getTronWeb
          return tronWeb ? this.getTronWeb.isAddress(value) : true
        },
      },
      sponsor: {
        required,
        existing() {
          return this.existingSponsor
        },
      },
      balance: {
        noZero: function () {
          return this.form.balance != 0
        },
        isNotEnough: function () {
          console.log('this.form.balance ', this.form.balance)
          return (
            this.form.balance >= this.globalPass[this.form.globalPass].amount
          )
        },
      },
      approved: {
        approvedNoZero: function () {
          return this.form.selectCoinSymbol != 'USDP'
            ? this.form.approved != 0
            : true
        },
        approvedIsNotEnough: function () {
          return this.form.selectCoinSymbol != 'USDP'
            ? this.form.approved >= this.globalPass[this.form.globalPass].amount
            : true
        },
      },
    },
    user: {
      globalPassMax: function () {
        return this.form.user.globalPass < 3
      },
      globalPassFollowers: function () {
        if (this.form.user.globalPass > 0 && this.form.user.followers < 2)
          return false
        return true
      },
    },
    validationGroup: ['user', 'form.address'],
    selectedCoin: ['form.balance', 'form.approved'],
    selectedMethods: {
      isNotAvailable: function (index) {
        console.log({ index, method: this.methods[index] })
        return !this.methods[index].disabled
      },
    },
  },
}
</script>

<style scoped>
.loading {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #28282885;
  border-radius: 20px;
}
.loading.active {
  z-index: 1001;
  display: flex;
}

.icon_x {
  position: absolute;
  top: 0;
  right: 0;
  color: #272622;
  font-family: 'Inter-Bold' !important;
  background-color: #FFD233;
  
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_x span {
  margin-bottom: 16%;
}
</style>
