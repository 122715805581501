import Vue from 'vue'
import Vuex from 'vuex'

import { wallet } from './modules/wallet.module.js'
import { coins } from './modules/coins.module.js'
import { sources } from './modules/sources.module.js'

import Config from '@/services/config.service.js'
import API_Tron from '@/services/API_Tron.service.js'

const apiTron = new API_Tron()

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: new Config().getConfig(),
  },
  mutations: {
    async init({ dispatch }) {
      console.log(1)
      await dispatch('wallet/connectWallet', { root: true })
      console.log(1)
    },
  },
  actions: {
    /** */
    async listen({ rootGetters }, txId) {
      //const vm = this
      let counter = txId == '' ? 60 : 0
      let _continue = true
      let waiting = 0
      const resp = await new Promise((resolve) => {
        const interval = setInterval(async () => {
          console.log(counter, { txId })
          if (_continue) {
            _continue = false
            const resp = await apiTron.gettransactionbyid(
              rootGetters['wallet/getRed'],
              txId
            )
            console.log(counter, { resp })
            if (
              (resp.data.revert && !resp.data.revert) ||
              (resp.data.contractRet && resp.data.contractRet !== 'SUCCESS') ||
              (resp.data.confirmations && resp.data.confirmations >= 1) ||
              counter === 60
            ) {
              console.log({ resp })
              resolve(resp.data)
              clearInterval(interval)
            }
            ++counter
            _continue = true
            waiting = 0
          } else {
            console.log({ waiting })
            if (++waiting > 60) {
              resolve({ revert: true })
              clearInterval(interval)
            }
          }
        }, 1000)
      })
      console.log({ resp })
      if (resp.revert || resp.contractRet !== 'SUCCESS') {
        throw new Error('Ups... Lo sentimos, ha ocurrido un error.')
      }
      return resp
    },
    /* energy query
      address
      item [user, community]
    */
    async getResources({ rootGetters }, address) {

      let result = {
        used: 0,
        limit: 0,
        available: 0,
      }
      const accountResources = await apiTron.getAccountResources(
        rootGetters['wallet/getTronWeb'],
        address
      )
      console.log({ accountResources })

      if (accountResources && Object.keys(accountResources).length === 0 )
        return null

      if (accountResources.EnergyLimit) {
        result.limit = accountResources.EnergyLimit
          ? accountResources.EnergyLimit
          : 0
        result.used = accountResources.EnergyUsed
          ? accountResources.EnergyUsed
          : 0
        result.available = result.limit - result.used
      }
      return result
    },
  },
  getters: {},
  modules: {
    wallet,
    coins,
    sources,
  },
})
