<template>
  <div class="home" id="home">
    <Nav></Nav>
    <b-container class="mb-5 pb-5" style="">
      <!--title -->
      <b-row class="mb-4">
        <b-col>
          <div class="title center-vh">
            <h1 class="t1 mb-0">Smart</h1>
            <div class="icon center-vh">
              <div class="triangle leyer-1">
                <div class="rounded-triangle"></div>
              </div>
              <div class="triangle leyer-2">
                <div class="rounded-triangle rounded-triangle-box-shadow"></div>
              </div>
              <img src="../assets/logo-gold.png" alt="Kitten" />
            </div>
            <h1 class="t1 mb-0">Infinity</h1>
          </div>
        </b-col>
      </b-row>

      <!--sction banner -->
      <b-row class="text-center mb-4">
        <b-col cols="12" class="mb-3">
          <div class="banner-1">
            <img src="../assets/banner.png" alt="Kitten" />
          </div>
        </b-col>
        <b-col cols="12" class="mb-4">
          <b-button class="btn-primary" @click="openModalBuyGlobalPass">
            <div class="bg-circle">
              <img
                src="../assets/arrow-white.png"
                alt="Kitten"
                style="margin-top: -2px"
              />
            </div>
            Comprar ahora
          </b-button>
        </b-col>
      </b-row>

      <!-- seccion -->
      <b-row class="mb-5">
        <b-col>
          <h1 class="t1 text-center mx-sm-3 mx-md-4 px-md-4 text-white mb-0">
            Una economía colaborativa 100% transparente y
            <span class="text-1">abierta para todos</span>
          </h1>
          <p class="t2 text-center text-white">
            Confiable - Sostenible - Descentralizado
          </p>
        </b-col>
      </b-row>

      <!-- seccion justLendDAO-->
      <b-row class="mb-5">
        <b-col class="text-center">
          <h1 class="t16 text-white">Ahorra costos de transacción con:</h1>
          <b-button
            class="btn-primary mb-3"
            @click="to(justlendDAO.WebSiteURL)"
          >
            <img
              :src="justlendDAO.icon"
              alt="Kitten"
              style="margin-top: -2px"
            />
            JustLendDAO
          </b-button>
        </b-col>
      </b-row>

      <!-- section -->
      <b-row class="mb-5">
        <b-col cols="12" class="text-center mb-4">
          <h3 class="text-1 t3">¿Que es SmartInfinity?</h3>
          <p class="t4 text-white mx-md-5">
            <span class="text-color-primary">SmartInfinity</span>
            es un ecosistema DeFi completamente descentralizado, seguro y
            automatizado mediante el uso de contratos inteligentes, que brinda a
            los usuarios acceso a una amplia gama de servicios:
          </p>
        </b-col>
        <b-col cols="12" style="position: relative" class="p-0">
          <b-container fluid>
            <b-row align-h="center">
              <b-col
                v-for="item in items"
                :key="item.title"
                cols="12"
                sm="6"
                md="6"
                lg="4"
                class="px-2"
              >
                <b-card
                  class="card-border text-center mx-auto mb-4"
                  body-class="px-sm-2"
                >
                  <div class="icon center-vh mx-auto mb-2">
                    <img :src="item.img" alt="Kitten" />
                  </div>
                  <b-card-title class="text-1 t6 m-0 mb-2">{{
                    item.title
                  }}</b-card-title>
                  <b-card-text text-tag="div" class="text-white t5">
                    <div v-html="item.description"></div>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-container>
          <div class="container-ellipse-2 center-vh">
            <div class="ellipse-2"></div>
          </div>
        </b-col>
      </b-row>

      <!-- Marketpalce -->
      <b-row class="">
        <b-col cols="12">
          <h3 class="text-center text-1 t3 mb-3">Marketpalce</h3>
        </b-col>
        <b-col cols="12" class="mb-2">
          <div style="position: relative">
            <b-carousel
              id="carousel_1"
              ref="carousel_1"
              v-model="slide"
              :interval="0"
              indicators
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333"
            >
              <b-carousel-slide
                v-for="(item, index) in marketplace"
                :key="index"
                :img-src="item.src"
                class="card-border"
              ></b-carousel-slide>
            </b-carousel>
            <!-- carousel-control -->
            <div>
              <a
                @click="$refs.carousel_1.prev()"
                class="carousel-control-prev center-vh"
              >
                <span class="carousel-control-prev-icon">
                  <img src="../assets/arrow-left.png" alt="Kitten" />
                </span>
              </a>
              <a @click="$refs.carousel_1.next()" class="carousel-control-next">
                <span class="carousel-control-next-icon">
                  <img src="../assets/arrow-right.png" alt="Kitten" />
                </span>
              </a>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <h3 class="text-center text-1 t7 mb-3">
            {{ marketplace[slide].title }}
          </h3>
        </b-col>
      </b-row>

      <!-- section ecosystem -->
      <b-row id="ecosystem" class="pt-5 mb-5">
        <b-col cols="12" class="mb-4">
          <h1 class="text-center text-1 t3 mb-3">Ecosistema SGBToken</h1>
          <p class="t4 text-center text-white">
            Bienvenidos a SGBT, la solución definitiva para problemas reales y
            usabilidad real para el ecosistema Blockchain.
          </p>
          <p class="t4 text-center text-white">
            Si estas cansado de tokens inflacionarios y poco seguros, SGBT es la
            opción perfecta para ti. Nuestro enfoque de soluciones DeFi y
            nuestros productos innovadores te ofrecen la mejor experiencia en la
            red de TRON.
          </p>
        </b-col>
        <b-col cols="12" style="position: relative" class="mb-4">
          <div class="ecosystem center-vh">
            <!-- levels -->
            <div
              v-for="i in levels.length"
              :key="i"
              :class="['center-vh circle-1 level-' + i]"
            >
              <img
                v-if="levels[i - 1][0].src_bg"
                :src="levels[i - 1][0].src_bg"
                alt="Kitten"
              />

              <div class="item" v-for="(item, j) in levels[i - 1]" :key="j">
                <div class="bg-item-circle item-icon center-vh">
                  <img class="" :src="item.src" alt="Kitten" />
                </div>
                <h6 class="item-title t8">{{ item.title }}</h6>
              </div>
            </div>
          </div>
          <div class="container-ellipse-2 center-vh">
            <div class="ellipse-3"></div>
          </div>
        </b-col>
      </b-row>

      <!-- section ecosystem info-->
      <b-row class="my-5 px-md-5 mx-md-2 px-lg-0 mx-lg-0">
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          class="pb-0 mt-5 px-0 d-flex justify-content-center"
          v-for="(item, index) in info"
          :key="index"
        >
          <div class="center-vh mr-2 mx-lg-2" style="height: 27px">
            <img :src="item.src" alt="Kitten" />
          </div>
          <div class="info">
            <h3 class="t4 text-white mb-0 center-v" style="height: 27px">
              {{ item.title }}
            </h3>
            <p
              class="mb-0"
              style="color: #c8c8c8"
              v-html="item.description"
            ></p>
          </div>
        </b-col>
      </b-row>

      <!-- section step to enter -->
      <b-row class="mt-5">
        <b-col cols="12" class="mt-4 mb-4">
          <h1 class="text-center text-1 t3 mb-3">
            Pasos para entrar a SmartInfinity
          </h1>
          <p class="t4 text-center text-white">
            Sigue unos sencillos pasos para comenzar a obtener ganancias en
            nuestro sistema basado en la increíble y revolucionaria tecnología
            Blockchain.
          </p>
        </b-col>
        <b-col>
          <div class="step-to-enter">
            <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/X7uWZwQaKSA"
              allowfullscreen
            ></b-embed>
          </div>
          <div class="container-ellipse-2 center-vh">
            <div class="ellipse-4"></div>
          </div>
        </b-col>
        <b-col cols="12" class="text-center mt-4">
          <b-button class="btn-primary" @click="openModalBuyGlobalPass">
            <div class="bg-circle">
              <img
                src="../assets/arrow-white.png"
                alt="Kitten"
                style="margin-top: -2px"
              />
            </div>
            Registrate
          </b-button>
        </b-col>
      </b-row>

      <!-- last transactions-->
      <b-row class="mt-5">
        <b-col cols="12" class="mt-4 mb-4">
          <h1 class="text-center text-1 t3 mb-3">Últimas transacciones</h1>
        </b-col>
        <b-col cols="12" class="">
          <Carousel3d
            ref="mycarousel"
            :width="260"
            :count="transactions.length"
          >
            <Slide :index="i" v-for="(item, i) in transactions" :key="i">
              <div class="px-3 pt-3 h-100" style="position: relative">
                <div class="mb-3 d-flex justify-content-between">
                  <div class="icon-bg center-vh">
                    <img src="../assets/logo-gold.png" alt="Kitten" />
                  </div>
                  <h3 class="t12">
                    ID {{ item.id.toString().padStart(4, 0) }}
                  </h3>
                </div>
                <h3 class="mb-0 t13">SALDO USD</h3>
                <h3 class="t14 mb-2">{{ item.balance.toFixed(2) }}</h3>
                <!---->
                <h3 class="t15 mb-0">
                  RECIBIDO: +{{ item.received.toFixed(2) + '$' }}
                  <a target="_blank" :href="item.link">
                    <img
                      src="../assets/link_icon.png"
                      alt="Kitten"
                      style="width: 12px"
                    />
                  </a>
                </h3>
                <h3 class="t15">{{ timestampToString(item.timeElapsed) }}</h3>
                <!---->
                <h3 class="mb-0 t13">FECHA DE REGISTRO</h3>
                <h3 class="t14">{{ formatterData(item.register) }}</h3>
                <div class="d-flex justify-content-end">
                  <div
                    class="icon-bg-2 center-vh ml-2"
                    v-for="j in item.globalpass"
                    :key="j"
                  >
                    <img :src="globalpass[j - 1]" alt="Kitten" />
                  </div>
                </div>
                <div
                  class="center-vh"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                  "
                >
                  <img
                    src="../assets/smart-infinity-logo.png"
                    alt="Kitten"
                    style="max-width: 220px"
                  />
                </div>
              </div>
            </Slide>
          </Carousel3d>
        </b-col>
      </b-row>

      <!-- results -->
      <b-row class="mt-5">
        <b-col cols="12" class="mt-4 mb-5">
          <h1 class="text-center text-1 t3 mb-3">Resultados de socios</h1>
          <p class="t4 text-center text-white">
            ¡Todos los datos se almacenan en la Blockchain de dominio público y
            se pueden verificar!
          </p>
        </b-col>
      </b-row>

      <b-row id="counters" ref="counters" class="" align-h="center">
        <b-col
          cols="12"
          md="4"
          class="mb-4 p-0"
          v-for="(item, i) in counters"
          :key="i"
        >
          <h6 class="t9 text-1 center-h">
            {{ mask(item.counter, item.isInteger) }}
            <span class="t10 text-2 ml-2">
              {{ item.last > 0 ? '+' + mask(item.last, item.isInteger) : '..' }}
            </span>
          </h6>
          <h3 class="t11 text-white center-h">
            {{ item.title }}
          </h3>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col cols="12" class="mt-dm-5 mb-4">
          <h1
            class="text-center text-1 t3 mx-auto mb-4"
            style="max-width: 470px"
          >
            Smart Infinity, una plataforma descentralizada de última generación
          </h1>
          <p class="t4 text-center text-white">
            Por primera vez, se presenta en el mercado una simbiosis tecnológica
            de primer nivel en el campo de Blockchain junto con Modelos
            Comerciales y de Marketing probados a través de los años. Smart
            Infinity es la herramienta perfecta y completamente transparente
            para tu crecimiento y bienestar financiero.
          </p>
        </b-col>
        <b-col cols="12" class="center-h">
          <button
            class="btn btn-border mx-1 mr-md-4"
            style="width: 145px; height: 37px"
            @click="to(config.getSourceLink())"
          >
            Ver Contrato
          </button>
          <button
            class="btn btn-secondary mx-1 ml-md-4"
            style="width: 145px; height: 37px"
            @click="openModalBuyGlobalPass"
          >
            Registrate
          </button>
        </b-col>
      </b-row>
    </b-container>
    <footer id="subscribe">
      <b-container fluid class="">
        <b-row class="mx-md-4 py-5">
          <!-- form -->
          <b-col cols="12" md="6" class="">
            <div style="max-width: 350px">
              <h3 class="t7">No te pierdas nada!</h3>
              <p class="t5">
                Sucríbete para obtener cualquier actualización ahora
              </p>
              <b-form
                class="mb-5 mb-md-1"
                style="position: relative"
                @submit.stop.prevent=""
              >
                <b-form-input
                  class=""
                  placeholder="Tu email"
                  style=""
                ></b-form-input>
                <button class="btn btn-primary">Suscribirme</button>
              </b-form>
            </div>
          </b-col>
          <!--socialNeworks-->
          <b-col cols="12" md="6" class="d-flex justify-content-md-end">
            <div class="mr-md-0" style="max-width: 350px">
              <p>
                Visita y suscríbete a nuestras Redes Sociales para que te
                mantengas actualizado en todos nuestros proyectos y futuras
                oportunidades!
              </p>
              <div
                class="d-flex justify-content-start justify-content-md-start"
              >
                <div
                  class="icon-bg center-vh mr-2"
                  v-for="(item, index) in socialNeworks"
                  v-bind:key="index"
                  @click="to(item.url)"
                >
                  <img :src="item.icon" alt="Kitten" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="border-top: 1px solid #636770">
          <b-col cols="12">
            <p class="t5 mt-2 ml-md-4 text-white">
              © 2023. Smart Infinity. Todos los derechos reservados. v2.0.0
            </p>
          </b-col>
        </b-row>
      </b-container>
    </footer>
    <div class="container-ellipse-1">
      <div class="ellipse-1"></div>
    </div>
    <!--modal -->
    <BuyGlobalPass @reStartDaemon="startDaemon" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import apiConcentration from '@/services/Concentration.service.js'
import Config from '@/services/config.service.js'
import Nav from '@/components/Nav.vue'
import BuyGlobalPass from '@/components/BuyGlobalPass/BuyGlobalPass.vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  name: 'Home',
  components: {
    Nav,
    BuyGlobalPass,
    Carousel3d,
    Slide,
  },
  data() {
    return {
      config: null,
      items: [
        {
          img: require('../assets/global-pass-icon.png'),
          title: 'GlobalPass',
          description:
            '<p>Las GlobalPass son tarjetas de ingreso que te ofrecen una amplia gama de beneficios exclusivos, tales como:</p><p>Financiamiento Colectivo <br /> Descuentos Exclusivos <br /> Planes de Compensación.</p>',
        },
        {
          img: require('../assets/marketplace-icon.png'),
          title: 'Marketplace',
          description:
            '<p>Nuestro Marketplace ofrece productos y servicios de alta calidad, que los usuarios pueden adquirir utilizando su tarjeta Globalpass.<br />Además, los usuarios pueden aprovechar nuestro innovador programa de marketing de afiliados que ofrecen nuestras GlobalPass para generar ingresos adicionales.</p>',
        },
        {
          img: require('../assets/sgbt-icon.png'),
          title: 'SGBToken',
          description:
            '<p>Descubre SGBToken, nuestro token basado en la tecnología blockchain de TRON.<br/><br/>Diseñado pensando en ti, ofrece una experiencia de usuario excepcional y fomenta una economía deflacionaria a largo plazo.</p>',
        },
      ],
      slide: 0,
      marketplace: [
        {
          title: 'GlobalPsss',
          src: require('../assets/carousel-1.png'),
        },
        {
          title: 'SmartSignal',
          src: require('../assets/carousel-2.png'),
        },
        {
          title: 'Hashminer',
          src: require('../assets/carousel-3.png'),
        },
      ],
      levels: [
        [
          {
            title: 'SmartSwap',
            src: require('../assets/ecosystem/smart-swap-icon.png'),
          },
          {
            title: 'Hashminer',
            src: require('../assets/ecosystem/hashminer-icon.png'),
          },
          {
            title: 'SGBTPad',
            src: require('../assets/ecosystem/sgbt-pad-icon.png'),
          },
          {
            title: 'Tique38',
            src: require('../assets/ecosystem/tique-icon.png'),
          },
          {
            title: 'Acrux Blockchain',
            src: require('../assets/ecosystem/acrux-icon.png'),
          },
          {
            title: 'EcoGlobal',
            src: require('../assets/ecosystem/eco-global-icon.png'),
          },
          {
            title: 'SmartAcademy',
            src: require('../assets/ecosystem/smart-academy-icon.png'),
          },
          {
            title: 'Staking',
            src: require('../assets/ecosystem/stake-icon.png'),
          },
        ],
        [
          {
            title: 'SmartSignal',
            src: require('../assets/ecosystem/smartsignal-icon.png'),
          },
          {
            title: 'SmartWallet',
            src: require('../assets/ecosystem/smart-wallet-icon.png'),
          },
          {
            title: 'SmartBot',
            src: require('../assets/ecosystem/smart-bot-icon.png'),
          },
        ],
        [
          {
            src_bg: require('../assets/sgbt-icon-2.png'),
            title: 'GlobalArt',
            src: require('../assets/ecosystem/globar-art-icon.png'),
          },
          {
            title: 'NexoCity\nMetaverso',
            src: require('../assets/ecosystem/nexocity-icon.png'),
          },
          {
            title: 'PLAY',
            src: require('../assets/ecosystem/play-icon.png'),
          },
        ],
      ],
      info: [
        {
          src: require('../assets/info/icon-1.png'),
          title: 'Adopción',
          description:
            'SGBToken se utilizará como Fee de retiros de ganancias y pagos de productos en SmartInfinity.',
        },
        {
          src: require('../assets/info/icon-2.png'),
          title: 'Crecimiento',
          description: 'Inyección de Liquidez en Sunswap hasta a los $0.01.',
        },
        {
          src: require('../assets/info/icon-3.png'),
          title: 'Comercialización',
          description:
            '¡Listado en Exchange y Preventa a precios increibles!\n$0.01 - $0.015 - $0.02',
        },
        {
          src: require('../assets/info/icon-4.png'),
          title: 'Exposición',
          description: `<ul>
              <li>Listado en Coinmarketcap</li>
              <li>Listado Coingeco.</li>
            </ul>`,
        },
        {
          src: require('../assets/info/icon-5.png'),
          title: 'Marketing',
          description: `<ul>
            <li>Crecimiento de Comunidad.</li>
            <li>AMAS cada mes con Influencers reconocidos.</li>
            <li>Herramientas de conversion.</li>
            <li>Pauta Digital.</li>
          </ul>`,
        },
        {
          src: require('../assets/info/icon-6.png'),
          title: 'Desarrollo',
          description:
            'SGBToken cuenta con un equipo propio de desarrolladores, por lo tanto nuestro proyecto estará en contante crecimiento sin interrupciones.',
        },
      ],
      counters: [
        {
          title: 'Participantes',
          counter: 0,
          last: 0,
          isInteger: true,
        },
        {
          title: 'Compras',
          counter: 0,
          last: 0,
          isInteger: true,
        },
        {
          title: 'Volumen de compras USD',
          counter: 0,
          last: 0,
          isInteger: false,
        },
      ],
      counterAnimate: [
        {
          counter: 0,
          last: 0,
          inc: 0.1,
        },
        {
          counter: 0,
          last: 0,
          inc: 0.1,
        },
        {
          counter: 0,
          last: 0,
          inc: 0.1,
        },
      ],
      IntervId: null,
      animated: false,
      socialNeworks: [
        {
          url: 'https://x.com/SmartInfinityN?t=4l_RIbKbohsRu7o4b8_g5g&s=09',
          icon: require('../assets/social-networks/x-icon.png'),
        },
        {
          url: 'https://www.facebook.com/smartinfinityoficial',
          icon: require('../assets/social-networks/facebook-icon.png'),
        },
        {
          url: 'https://t.me/smartinfinity7',
          icon: require('../assets/social-networks/telegram-icon.png'),
        },
        {
          url: 'https://www.youtube.com/channel/UCk_M7umd7sg57FprTw3k_rQ',
          icon: require('../assets/social-networks/youtube-icon.png'),
        },
        {
          url: 'https://www.tiktok.com/@smartinfinityoficial?_t=8kMO96HIokP&_r=1',
          icon: require('../assets/social-networks/tiktok-icon.png'),
        },
        {
          url: 'https://www.instagram.com/smartinfinityoficial?igsh=anZjbjVmNm5lajZ6',
          icon: require('../assets/social-networks/instagram-icon.png'),
        },
      ],
      globalpass: [
        require('../assets/globalpass/bronze-icon.png'),
        require('../assets/globalpass/silver-icon.png'),
        require('../assets/globalpass/gold-icon.png'),
      ],
      transactions: [],
      daemonID: null,
      updating: false,
      justlendDAO: {
        icon: require('../assets/justLendDAO.png'),
        WebSiteURL: 'https://app.justlend.org/energy?lang=en-US',
      },
    }
  },
  computed: {
    ...mapGetters({
      getStatusWallet: 'wallet/getStatusWallet',
      getTronWebIsLoggedIn: 'wallet/isLoggedIn',
      getStatusPluginOptions: 'wallet/getStatusPluginOptions',
    }),
  },
  methods: {
    to: (url) => window.open(url, '_blank'),
    async openModalBuyGlobalPass() {
      let data = {
        type: '',
        message: '',
      }

      try {
        // solo si plugins is install
        if (this.getStatusWallet == this.getStatusPluginOptions.installing) {
          data.type = 'error'
          data.message = '<p class="t21 mb-0">' + this.$Errors[0] + '</p>'
          throw new Error()
        }
        // set address
        this.$bvModal.show('modal-buy')
        if (!this.getTronWebIsLoggedIn) {
          data.type = 'warning'
          data.message = '<p class="t21 mb-0">' + this.$Errors[1] + '</p>'
          this.$emit('notification', { data })
        }
      } catch (e) {
        console.log({ e })
        this.$emit('notification', { data })
      }
    },
    /* separa los cientos y miles con un espacio*/
    mask(value, isInteger) {
      if (value == 0) {
        return '--'
      }
      var aux = value.toFixed(2).split('.')
      let str = '' + aux[0] // value
      let start = 0
      let end = str.length
      let result = ''
      do {
        start = end - 3
        if (start < 0) {
          start = 0
        }
        result = str.slice(start, end) + ' ' + result
        end -= 3
      } while (start > 0)
      return result + (isInteger ? '' : '.' + aux[1])
    },
    resetCounter() {
      for (var i = 0; i < this.counters.length; i++) {
        this.counters[i].last = 0
        this.counters[i].counter = 0
      }
    },
    async setCounter() {
      try {
        const resp = await apiConcentration.getStatusSI()
        // set accounts
        // set received amount
        let total = 0
        const plansAmount = {
          1: 30,
          2: 60,
          3: 120,
        }
        Object.entries(resp.data.totals.PlanReceived).forEach((element) => {
          const [k, v] = element
          total += plansAmount[k] * v.counter
        })

        this.counters[0].last = resp.data.filter.planReceived['1'].counter
        this.counters[0].counter = resp.data.totals.PlanReceived['1'].counter

        this.counters[1].last = resp.data.filter.received.counter
        this.counters[1].counter = resp.data.totals.received.counter

        this.counters[2].last = resp.data.filter.received.amount / 1000000
        this.counters[2].counter = total

        if (this.IntervId != null) {
          clearInterval(this.IntervId)
          this.IntervId = null
        }

        if (this.animated) {
          this.animated = false
        }
        this.startAnimationCounter()
      } catch (error) {
        console.log({ error })
        this.resetCounter()
      }
    },
    getCounter() {
      return this.counters
    },
    stoptAnimationCounter() {
      if (this.IntervId != null || this.animated) {
        for (let i = 0; i < this.counters.length; i++) {
          this.counters[i].last = this.counterAnimate[i].last
          this.counters[i].counter = this.counterAnimate[i].counter
        }
      }
      if (this.IntervId != null) {
        clearInterval(this.IntervId)
        this.IntervId = null
      }

      if (this.animated) {
        this.animated = false
      }
    },
    startAnimationCounter() {
      if (!this.IntervId && !this.animated) {
        this.animated = true

        this.counterAnimate = []
        for (let i = 0; i < this.counters.length; i++) {
          let inc = Math.trunc(this.counters[i].counter * 0.105)
          this.counterAnimate[i] = {
            counter: this.counters[i].counter,
            last: this.counters[i].last,
            inc: inc == 0 ? 1 : inc,
          }
          // reset
          this.counters[i].counter = 0
          this.counters[i].last = 0
        }

        let stop
        let x
        const keys = ['counter', 'last']
        const vm = this
        this.IntervId = setInterval(() => {
          stop = true
          for (let i = 0; i < vm.counters.length; i++) {
            for (let j = 0; j < 2; j++) {
              if (vm.counterAnimate[i][keys[j]] > vm.counters[i][keys[j]]) {
                x = this.counters[i][keys[j]] + vm.counterAnimate[i].inc

                if (x > vm.counterAnimate[i][keys[j]]) {
                  vm.counters[i][keys[j]] = vm.counterAnimate[i][keys[j]]
                } else {
                  this.counters[i][keys[j]] = x
                  stop = false
                }
              }
            }
          }
          if (stop) {
            clearInterval(vm.IntervId)
            vm.IntervId = null
          }
        }, 100)
      }
    },
    formatterData(timestam) {
      const data = new Date(timestam * 1000)
      return (
        data.getDate().toString().padStart(2, 0) +
        '.' +
        (data.getMonth() + 1).toString().padStart(2, 0) +
        '.' +
        data.getFullYear()
      )
    },
    timestampToString(timestamp) {
      const sec = 1
      const min = 60 * sec
      const hours = min * 60
      const days = hours * 24
      const year = days * 365
      let value = 0
      let group = ''
      timestamp = timestamp > 0 ? timestamp : 0
      if (timestamp > year) {
        value = timestamp / year
        group = 'AÑOS'
      } else if (timestamp > days) {
        value = timestamp / days
        group = 'DIAS'
      } else if (timestamp > hours) {
        value = timestamp / hours
        group = 'HORAS'
      } else if (timestamp > min) {
        value = timestamp / min
        group = 'MIN'
      } else {
        value = timestamp
        group = 'SEC'
      }
      return Math.trunc(value) + ' ' + group
    },
    /* last transactions */
    async getLastTransactions() {
      try {
        const resp = await apiConcentration.getDepositsInfo()
        this.transactions = []
        let transactions = []

        let aux = {}
        const now = Date.now()
        let j = 0
        for (let i = 0; i < resp.data.log.length; i++) {
          if (resp.data.addressUser[resp.data.log[i].toAddress]) {
            aux = {
              id: resp.data.addressUser[resp.data.log[i].toAddress].id,
              balance:
                resp.data.addressUser[resp.data.log[i].toAddress].received
                  .amount / 1000000,
              received: (resp.data.log[i].amount * 95) / 100000000, // 95%
              register:
                resp.data.addressUser[resp.data.log[i].toAddress].register,
              timeElapsed: 0,
              globalpass:
                resp.data.addressUser[resp.data.log[i].toAddress].plan,
              link:
                this.config.getOfficialWebsite() +
                'transaction/' +
                resp.data.log[i].transactionId,
            }
            aux.timeElapsed = (now - resp.data.log[i].blockTimestamp) / 1000
            transactions[j] = aux
            j++
          }
        }
        this.transactions.push(...transactions)
        if (this.$refs.mycarousel) {
          this.$refs.mycarousel.goSlide(0)
        }
      } catch (error) {
        console.log({ error })
      }
    },
    stopDaemon() {
      if (this.daemonID) clearTimeout(this.daemonID)
    },
    async updateCounter() {
      if (!this.updating) {
        this.updating = true
        await this.setCounter()
        await this.getLastTransactions()
        this.updating = false
      }
    },
    async startDaemon() {
      this.stopDaemon()
      this.updateCounter()
      const vm = this
      this.daemonID = setInterval(
        () => new Promise(() => vm.updateCounter()),
        60000
      )
    },
  },
  created() {
    this.config = new Config()
    this.startDaemon()
  },
  mounted() {
    const vm = this
    document.querySelector('#app').addEventListener('scroll', function (item) {
      const scrollY = item.target.scrollTop
      const scrollH = window.screen.height // viewport
      const elem = vm.$refs.counters
      if (elem != undefined) {
        const delta = elem.offsetTop - scrollH
        if (scrollY >= delta && scrollY < elem.offsetTop) {
          vm.startAnimationCounter()
        } else {
          vm.stoptAnimationCounter()
        }
      }
    })
  },
}
</script>

<style>
.t1 {
  font-family: 'DMSans-Bold';
  font-size: 40px;
  line-height: 38px;
}

.t2 {
  font-family: 'DMSans-Regular';
  font-size: 12px;
  line-height: 50px;
}

.t3 {
  font-family: 'DMSans-Bold';
  font-size: 25px;
  line-height: 30px;
}

.t4 {
  font-family: 'DMSans-Regular';
  font-size: 20px;
  line-height: 30px;
}

.t5 {
  font-family: 'DMSans-Regular';
  font-size: 14px;
  line-height: 16px;
}

.t6 {
  font-family: 'DMSans-Bold';
  font-size: 20px;
  line-height: 30px;
}

.t7 {
  font-family: 'DMSans-Bold';
  font-size: 20px;
  line-height: 30px;
}

.t8 {
  font-family: 'DMSans-Bold';
  font-size: 10px;
  line-height: 10px;
}

.t9,
.t10 {
  font-family: 'DMSans-Bold';
  font-size: 40px;
  line-height: 30px;
}

.t10 {
  font-size: 25px;
}

.t11 {
  font-family: 'DMSans-Bold';
  font-size: 18px;
  line-height: 30px;
}

.t12 {
  font-family: 'DMSans-Bold';
  font-size: 15px;
  line-height: 24px;
  color: #202020;
}

.t13,
.t14 {
  font-family: 'DMSans-Bold';
  font-size: 16px;
  line-height: 22px;
  color: #202020;
}

.t14 {
  font-family: 'DMSans-Regular';
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.t15 {
  font-family: 'DMSans-Bold';
  font-size: 18px;
  line-height: 22px;
  color: #202020;
}
.t16 {
  font-family: 'DMSans-Medium';
  font-size: 25px;
  line-height: 38px;
}
.modal-buy .t1 {
  font-family: 'Inter-Bold';
  font-size: 22px;
  line-height: 20px;
}

.modal-buy .t2 {
  font-family: 'Inter-Bold' !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0em !important;
}

.modal-buy form .t16 {
  font-family: 'Inter-SemiBold';
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
/*eliminar ?*/
.modal-buy form .t17 {
  font-family: 'Inter-Light';
}

.modal-buy form .t18 {
  font-family: 'Inter-Bold';
}

.modal-buy form .t19 {
  font-family: 'Inter-Regular';
  font-size: 13px;
  line-height: 18px;
}
.modal-buy form .t20 {
  font-family: 'Inter-Light';
  font-size: 10px;
  line-height: 18px;
}

.text-1 {
  background: linear-gradient(90deg, #ffd233 20.27%, #fff3c9 86.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-2 {
  background: #5cff33;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/**** ****/
.container-ellipse-1 {
  position: absolute;
  top: -160px;

  width: 100%;
  display: flex;
  justify-content: center;

  z-index: -3;
}
.ellipse-1 {
  width: 155px;
  height: 155px;
  border-radius: 50%;

  background: rgba(250, 234, 177, 1);
  box-shadow: 0px 0px 140px 140px rgba(255, 226, 42, 0.4);
}
.container-ellipse-2 {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
  z-index: -1;
}
.ellipse-2,
.ellipse-4 {
  width: 0px;
  height: 0px;
  border-radius: 50%;

  background: rgba(250, 234, 177, 1);
  box-shadow: 0px 0px 140px 140px rgba(255, 226, 42, 0.4);
}
.ellipse-3 {
  width: 0px;
  height: 0px;
  border-radius: 50%;

  background: rgba(250, 234, 177, 1);
  box-shadow: 0px 0px 180px 180px rgb(255 226 42 / 40%);
}
.ellipse-4 {
  width: 30%;
  height: 30%;
}

/*** section title ****/
.title {
  color: white;
  margin-top: 130px;
}

.icon {
  width: 63px;
  height: 63px;
}

.leyer-1 {
  z-index: -1;
}
.leyer-2 {
  z-index: -2;
}

.triangle {
  width: 43px;
  height: 43px;
  position: absolute;
}

.rounded-triangle,
.rounded-triangle:before,
.rounded-triangle:after {
  width: 30px;
  height: 30px;
  border-top-right-radius: 75%;
  background-color: #070707;
}

.rounded-triangle {
  margin: 0px auto 0 auto;
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}

.rounded-triangle:before,
.rounded-triangle:after {
  content: '';
  position: absolute;
}

.rounded-triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}

.rounded-triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.rounded-triangle-box-shadow,
.rounded-triangle-box-shadow:before,
.rounded-triangle-box-shadow:after {
  box-shadow: 0px 0px 11px 2px #ffe22ad9;
  border-radius: 16px 60px 16px 16px;
}

/*******/
.banner-1 img {
  width: 100%;
}

.bg-circle {
  display: inline-block;
  background-color: #131313;
  border-radius: 50%;
  width: 22px;
  margin: 0;
}
/**** ***/
.card {
  max-width: 300px !important;
}

.card .card-body {
  height: 350px !important;
}

.card-border {
  border: double 0.5px transparent !important;
  border-radius: 15px !important;
  background-image: linear-gradient(#121212 0 0),
    linear-gradient(180deg, #ffd233 0%, #fff3c9 100%) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}

.carousel .card-border {
  border: double 0.5px transparent !important;
  border-radius: 10px !important;
}
.carousel img {
  border-radius: 10px !important;
}

/*.carousel*/
.carousel-item.card-border {
  border-width: 2px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none !important;
  background-color: #ffd233 !important;
  border-radius: 50%;
}
.carousel-control-prev-icon img,
.carousel-control-next-icon img {
  margin-top: -7px;
}
.carousel-indicators li {
  background-color: #ffd233 !important;
}
.card .icon {
  width: 88px;
  height: 88px;
  background: linear-gradient(180deg, #ffd233 33.63%, #faeab1 100%);
  border-radius: 50%;
}

/***** ecosystem */
.ecosystem {
  position: relative;
  width: 500px;
  height: 500px;
  margin: auto;
}

.ecosystem .item {
  max-width: 60px;
}
.ecosystem div.circle-1 {
  position: absolute;
  border-radius: 50%;
  border: 0.5px solid rgba(157, 157, 157, 1);
  background: linear-gradient(
    0deg,
    rgba(255, 210, 51, 0.2),
    rgba(255, 210, 51, 0.2)
  );
}

.ecosystem .level-1 {
  width: 100%;
  height: 100%;
}
.ecosystem .level-2 {
  width: 75%;
  height: 75%;

  background: linear-gradient(
    0deg,
    rgba(255, 210, 51, 0.1),
    rgba(255, 210, 51, 0.1)
  );
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
}
.ecosystem .level-3 {
  width: 50%;
  height: 50%;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
}

.ecosystem .bg-item-circle {
  position: absolute;
  border-radius: 50%;

  border: 1px solid;
  border-image-source: linear-gradient(
    179.92deg,
    #ffd954 4.04%,
    #5f5f5f 99.93%
  );
  background: linear-gradient(180deg, #575757 0%, #202020 55.71%),
    linear-gradient(179.92deg, #ffd954 4.04%, #5f5f5f 99.93%);

  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}

.ecosystem .item {
  position: absolute;
}
.ecosystem .item-title {
  color: white;
  text-align: center;
  margin: 4px 0 0 0;
}
.ecosystem .item-icon {
  position: relative;
  margin: auto;
}

/* level 3*/
.ecosystem .level-1 > div:nth-last-of-type(8) {
  top: 5%;
  left: 12%;
}
.ecosystem .level-1 > div:nth-last-of-type(8) .item-icon {
  height: 56px;
  width: 56px;
}

.ecosystem .level-1 > div:nth-last-of-type(7) {
  top: 0;
  right: 15%;
}
.ecosystem .level-1 > div:nth-last-of-type(7) .item-icon {
  height: 76px;
  width: 76px;
}

.ecosystem .level-1 > div:nth-last-of-type(6) {
  top: 25%;
  right: 0;
}
.ecosystem .level-1 > div:nth-last-of-type(6) .item-icon {
  height: 62px;
  width: 62px;
}

.ecosystem .level-1 > div:nth-last-of-type(5) {
  top: 50%;
  right: -7%;
}
.ecosystem .level-1 > div:nth-last-of-type(5) .item-icon {
  height: 70px;
  width: 70px;
}

.ecosystem .level-1 > div:nth-last-of-type(4) {
  bottom: -5%;
  right: 20%;
}
.ecosystem .level-1 > div:nth-last-of-type(4) .item-icon {
  height: 60px;
  width: 60px;
}

.ecosystem .level-1 > div:nth-last-of-type(3) {
  bottom: -10%;
  right: 50%;
}
.ecosystem .level-1 > div:nth-last-of-type(3) .item-icon {
  height: 62px;
  width: 62px;
}

.ecosystem .level-1 > div:nth-last-of-type(2) {
  bottom: 25%;
  left: -5%;
}
.ecosystem .level-1 > div:nth-last-of-type(2) .item-icon {
  height: 68px;
  width: 68px;
}

.ecosystem .level-1 > div:nth-last-of-type(1) {
  top: 33%;
  left: -6%;
}
.ecosystem .level-1 > div:nth-last-of-type(1) .item-icon {
  height: 66px;
  width: 66px;
}

/* level 2*/
.ecosystem .level-2 > div:nth-last-of-type(3) {
  top: -5%;
  left: 30%;
}
.ecosystem .level-2 > div:nth-last-of-type(3) .item-icon {
  height: 66px;
  width: 66px;
}

.ecosystem .level-2 > div:nth-last-of-type(2) {
  top: 65%;
  right: -3%;
}
.ecosystem .level-2 > div:nth-last-of-type(2) .item-icon {
  height: 62px;
  width: 62px;
}

.ecosystem .level-2 > div:nth-last-of-type(1) {
  top: 80%;
  left: 35px;
}
.ecosystem .level-2 > div:nth-last-of-type(1) .item-icon {
  height: 68px;
  width: 68px;
}

/* level 3*/
.ecosystem .level-3 > div:nth-last-of-type(3) {
  top: 10px;
  left: 0;
}
.ecosystem .level-3 > div:nth-last-of-type(3) .item-icon {
  height: 54px;
  width: 54px;
}
.ecosystem .level-3 > div:nth-last-of-type(3) .item-icon img {
  margin-left: 5px;
}

.ecosystem .level-3 > div:nth-last-of-type(2) {
  top: 0;
  right: 0;
}
.ecosystem .level-3 > div:nth-last-of-type(2) .item-icon {
  height: 58px;
  width: 58px;
}

.ecosystem .level-3 > div:nth-last-of-type(1) {
  bottom: -12%;
  left: 50%;
}
.ecosystem .level-3 > div:nth-last-of-type(1) .item-icon {
  height: 60px;
  width: 60px;
}

.info {
  width: 100%;
  max-width: 250px;
}

.info ul {
  padding-left: 20px !important;
  margin-bottom: 0;
}

/**/
.step-to-enter {
  padding: 5px;
  border-radius: 6px;
  background: linear-gradient(180deg, #313131 0%, #000000 100%);
}

/****/
.carousel-3d-slide {
  border: 2px solid #ffc500 !important;
  border-radius: 10px !important;
  background: linear-gradient(
    87.21deg,
    #fff3c9 14.06%,
    #ffd233 100%
  ) !important;
}
.carousel-3d-slide .icon-bg,
.carousel-3d-slide .icon-bg-2 {
  background-color: #202020;
  border-radius: 50%;
}

.carousel-3d-slide .icon-bg {
  width: 42px;
  height: 42px;
}
.carousel-3d-slide .icon-bg-2 {
  width: 28px;
  height: 28px;
}

.carousel-3d-slide .icon-bg img {
  width: 25px !important;
}

.carousel-3d-slide .icon-bg-2 img {
  width: 20px !important;
}
/* footer */
footer {
  text-align: left !important;
  color: #ffffffcc !important;
  background-color: #37373780 !important;
}
footer p {
  color: #f6f6f6cc;
}
footer .icon-bg {
  background-color: #f2c94c33;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
footer .icon-bg:hover {
  background: linear-gradient(
    87.21deg,
    #ffd954 14.06%,
    #faeab1 100%
  ) !important;
  opacity: 0.8;
}
footer .form-control {
  height: 45px !important;
  background-color: #ffffff1a !important;
  border-radius: 0.5rem !important;
}
footer .form-control:focus {
  border-color: #ffd233ff !important;
  box-shadow: 0 0 0 0.2rem #ffd2333b !important;
}

footer form button {
  position: absolute;
  right: 4px;
  bottom: 4px;
}

@media (max-width: 575.98px) {
  .card .card-body {
    width: 300px !important;
    height: 370px !important;
  }
}

@media (max-width: 767.98px) {
  .t1 {
    font-size: 28px;
    line-height: 26px;
  }
  .t3,
  .t7 {
    font-size: 18px;
    line-height: 30px;
  }
  .t4,
  .t5 {
    font-size: 15px;
    line-height: 20px;
  }
  .t6 {
    font-size: 18px;
    line-height: 30px;
  }
  .t8 {
    font-family: 'DMSans-Bold';
    font-size: 8px;
    line-height: 8px;
  }
  .t9,
  .t10 {
    font-size: 36px;
  }

  .t10 {
    font-size: 22px;
  }
  .t16 {
    font-size: 17px;
  }

  .ecosystem {
    width: 314px;
    height: 314px;
  }
  .ellipse-3 {
    box-shadow: 0px 0px 140px 140px rgba(255, 226, 42, 0.4);
  }
  .ecosystem .bg-item-circle img {
    scale: 0.6;
  }

  /* level 1,2, 3*/
  .ecosystem .level-2 > div:nth-last-of-type(3) .item-icon,
  .ecosystem .level-2 > div:nth-last-of-type(1) .item-icon,
  .ecosystem .level-1 > div:nth-last-of-type(1) .item-icon {
    width: 41px;
    height: 41px;
  }
  .ecosystem .level-2 > div:nth-last-of-type(2) .item-icon {
    width: 38px;
    height: 38px;
  }
  .ecosystem .level-1 > div:nth-last-of-type(8) .item-icon {
    width: 31px;
    height: 31px;
  }
  .ecosystem .level-1 > div:nth-last-of-type(7) .item-icon {
    width: 47px;
    height: 47px;
  }
  .ecosystem .level-1 > div:nth-last-of-type(6) .item-icon,
  .ecosystem .level-1 > div:nth-last-of-type(3) .item-icon {
    width: 39px;
    height: 39px;
  }
  .ecosystem .level-1 > div:nth-last-of-type(5) .item-icon {
    width: 44px;
    height: 44px;
  }
  .ecosystem .level-1 > div:nth-last-of-type(2) .item-icon {
    width: 42px;
    height: 42px;
  }
  /* level 3*/
  .ecosystem .level-3 > div:nth-last-of-type(3) .item-icon {
    width: 33px;
    height: 33px;
  }
  .ecosystem .level-3 > div:nth-last-of-type(2) .item-icon {
    width: 35px;
    height: 35px;
  }
  .ecosystem .level-3 > div:nth-last-of-type(1) .item-icon,
  .ecosystem .level-1 > div:nth-last-of-type(4) .item-icon {
    width: 37px;
    height: 37px;
  }
}

@media (max-width: 959.98px) {
  .t9 {
    font-size: 28px;
  }
  .t10 {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .t11 {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .t9 {
    font-size: 30px;
  }
  .t10 {
    font-size: 15px;
  }
}
</style>
