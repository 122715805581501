const data = {
  env: {
    production: {
      //coins
      USDT: { address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t' },
      USDD: { address: 'TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn', tokenId: '' },
      USDC: { address: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8' },
      USDP: { address: 'TAHuh9ZXT2TBC8PT6QSvih122qMbZZt9Sa' },
      SGBT: {
        address: 'TCdCXwD9GPmJUVaB64KeGU6ovd34XgFNe7',
        tokenId: '1004796',
      },
      PISTIS: {
        address: 'TJpUfLRPLbM8KhgKGWFzinqedZrRQZ9JkC',
        tokenId: '1004938',
      },
      smartInfinity: 'TT5Z7rkoVCyF2t8Sc6JpJw92QB7FAwUphB',
      dashboardURL: 'https://dashboard.smartinfinity.vip',
      officialWebsite: 'https://tronscan.org/#/',
    },
    development: {
      //coins
      USDT: { address: 'TXYZopYRdj2D9XRtbG411XZZ3kM5VkAeBf' },
      USDD: {
        address: 'TELKVv8rSUKBvNseBGmxZxujAKCX8ESbSH',
        tokenId: '1004777',
      },
      USDC: { address: '' },
      USDP: { address: 'TH8oMv7phRMRW8JWHijCnkkxEL22Y69m1j' },
      SGBT: {
        address: 'TRYGHNfz6ghkYRz6B6wx7wUxPvhWB3yk7K',
        tokenId: '1005252',
      },
      PISTIS: {
        address: 'TJH9imnr5sLsQWWqLq5yCvE5c9mKbE8RWt',
        tokenId: '1005039',
      },
      smartInfinity: 'TX9yaMVGQjEcKSPY8xM84DdxXVuPmyoesc',
      dashboardURL: 'https://dashboard-dev.smartinfinity.vip',
      officialWebsite: 'https://nile.tronscan.org/#/',
    },
  },
}
data.env['preDev'] = data.env.development
console.log({ env: process.env.NODE_ENV })
export default class Config {
  getConfig() {
    return data.env[process.env.NODE_ENV]
  }
  getOfficialWebsite() {
    return data.env[process.env.NODE_ENV].officialWebsite
  }
  getSourceLink() {
    return (
      data.env[process.env.NODE_ENV].officialWebsite +
      'contract/' +
      data.env[process.env.NODE_ENV].smartInfinity +
      '/code'
    )
  }
  getDashhoardURL() {
    return data.env[process.env.NODE_ENV].dashboardURL
  }
}
