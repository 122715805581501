import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import global_ from './global' // archivo de referencia

Vue.use(Vuelidate)
Vue.config.productionTip = false

Vue.prototype.GLOBAL = global_ // Instalar en instancia de Vue

Vue.prototype.$Errors = [
  /*0*/ 'La wallet se está sincronizando. Por favor, espere unos segundos y vuelva a intentarlo.',
  /*1*/ 'No se ha detectado direccion activa, verificar si su wallet esta instalanda y desbloqueada.',
  /*2*/ 'Lo sentimos, ya usted cuenta con todas las GlobalPass.',
  /*3*/ 'Lo sentimos, para actualizar GlobalPass necesita tener 2 invitado.',
  /*4*/ 'La dirección [?], no existe en la blockchain, requiere 1.1 TRX',
]

require('./plugins/Bootstrap')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
