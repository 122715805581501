<template>
  <div class="contenedor">
    <!-- preloader / spiner -->
    <div class="preloader" :class="[ this._isLoading ? 'active' :'' ]" >
      <div class="h-100 w-100 d-flex justify-content-center mb-3 text-center">
        <img src="./assets/Preload1.gif"/>
      </div>
    </div>
    <div id="app" :class="[this._isLoading ? 'active' :'']">
      <router-view :key="$route.fullPath" @notification="notification"/>
      <b-modal
        modal-class="modal-notification"
        id="modal-notification"
        ref="modal-notification"
        body-class="bg-5 p-4 text-center"
        header-class= "bg-5 pt-5 justify-content-center"
        content-class= "bg-4"
        footer-class= "bg-5 pb-5 justify-content-center"
        :no-close-on-backdrop = "noCloseOnBackdrop()"
        centered
      >
        <template #modal-header="{}">
          <div class="bg-3" style="border-radius: 50%">
            <div class="center-vh" style="width: 40px; height: 40px">
              <div style="display: contents">
                <img :src="notificationData.data.url" />
              </div>
            </div>
          </div>
        </template>
        <template  #default="{}" >
          <div class="text-white" v-html = "notificationData.data.message"></div>
        </template>
        <template #modal-footer="{}">
          <b-button
            v-if ="notificationData.cancelTitle && notificationData.cancelTitle != ''"
            @click="_cancel"
          >
            {{ notificationData.cancelTitle }}
          </b-button>
          <b-button
            v-if ="notificationData.okTitle!=''"
            variant="primary"
            @click="_confirm"
          >
          {{ notificationData.okTitle }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  data () { return {
    isLoading: true,
    notificationData: {
      id: null,
      icon: {
        icon_x: require('@/assets/check-x-icon.png'),
        icon_ok: require('@/assets/check-ok-icon.png'),
        icon_warning: require('@/assets/check-warning-icon.png'),
      },
      data: {
        url: null,
        message: '',
      },
      okTitle: 'Ok',
      cancelTitle: '',
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
      noCloseOnBackdrop: false,
    }
  }},
  computed: {
    ...mapGetters({
      getTronWeb: 'wallet/getTronWeb',
      getStatusWallet: 'wallet/getStatusWallet',
      getStatusPluginOptions: 'wallet/getStatusPluginOptions'
    }),
    _isLoading() {
      return this.isLoading
    },
  },
  async created () {
    console.log('created')
    await this.$store.dispatch('wallet/connectWallet')
    const vm = this
    window.addEventListener('message', function (e) {
      if (e.data.message && e.data.message.action == "accountsChanged") {
        console.log({data: e.data})
        vm.$store.dispatch('wallet/connectWallet')
      }
    })
  },
  async mounted () {
    const vm = this
    setTimeout(function() {
      vm.isLoading = false
    }, 5000)
    console.log('mounted')
  },
  methods: {
    _confirm() {
      //https://stackabuse.com/how-to-create-a-confirmation-dialogue-in-vue-js/
      this.$bvModal.hide('modal-notification')
      if (this.notificationData.resolvePromise)
        this.notificationData.resolvePromise(true)
    },
    _cancel() {
      this.$bvModal.hide('modal-notification')
      if (this.notificationData.resolvePromise)
        this.notificationData.resolvePromise(false)
    },
    notificationReset() {
      this.notificationData.data = {
        url: null,
        message: '',
      }
      this.notificationData.noCloseOnBackdrop = false,
      this.notificationData.okTitle = 'Ok'
      this.notificationData.cancelTitle = ''
      // Private variables
      this.notificationData.resolvePromise = undefined
      this.notificationData.rejectPromise = undefined
      this.notificationData.noCloseOnBackdrop = false
    },
    noCloseOnBackdrop() {
      return this.notificationData.noCloseOnBackdrop
    },
    // https://stackoverflow.com/questions/66583752/vue-async-await-with-emit
    notification: function({ resolve, data }) {
      // reset
      this.notificationReset()
      if (data.noCloseOnBackdrop) {
        this.notificationData.noCloseOnBackdrop = data.noCloseOnBackdrop
      }
      //type notifications
      if (data.cancelTitle) {
        this.notificationData.cancelTitle = data.cancelTitle
      }
      if (data.type == 'ok') {
        this.notificationData.data.url = this.notificationData.icon.icon_ok
      } else if (data.type == 'warning') {
        this.notificationData.data.url = this.notificationData.icon.icon_warning

      }else {
        this.notificationData.data.url = this.notificationData.icon.icon_x
      }

      console.log('notification', { data })
      this.notificationData.data.message = data.message

      this.$bvModal.show('modal-notification')
      this.notificationData.resolvePromise = resolve
    }
  },
  watch: {
    async getStatusWallet (_new, old) {
      console.log('getStatusWallet:', { old, _new })
      // wallet installed
      if (this.getStatusPluginOptions.installed == _new) {
        // buscar contrato smart infinity
        await this.$store.dispatch('sources/initSmartContract')
      }
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'DMSans-Regular';
  src: local('DMSans-Regular'),
    url('~@/assets/fonts/DM_Sans/DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: local('DMSans-Medium'),
    url('~@/assets/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: local('DMSans-Bold'),
    url('~@/assets/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
}

/* Inter */
@font-face{
  font-family: 'Inter-Light';
  src:
    local('Inter-Light'),
    url('~@/assets/fonts/Inter/Inter-Light.ttf')format('truetype');
}

@font-face{
  font-family: 'Inter-Regular';
  src:
    local('Inter-Regular'),
    url('~@/assets/fonts/Inter/Inter-Regular.ttf')format('truetype');
}
@font-face{
  font-family: 'Inter-Medium';
  src:
    local('Inter-Medium'),
    url('~@/assets/fonts/Inter/Inter-Medium.ttf')format('truetype');
}
@font-face{
  font-family: "Inter-Bold";
  src:
    local("Inter-Bold"),
    url('~@/assets/fonts/Inter/Inter-Bold.ttf')format('truetype');
}

@font-face{
  font-family: 'Inter-SemiBold';
  src:
    local('Inter-SemiBold'),
    url('~@/assets/fonts/Inter/Inter-SemiBold.ttf')format('truetype');
}

@font-face{
  font-family: "Inter-ExtraBold";
  src:
    local("Inter-ExtraBold"),
    url('~@/assets/fonts/Inter/Inter-ExtraBold.ttf')format('truetype');
}

body {
  height: 100%;
  background-color: #070707 !important;
}
.contenedor{
  height: 100%;
}
div#app {
  position: absolute;
  height: 100%;
  width: 100%;
}

div#app {
  overflow: auto; /*Graceful degradation para Firefox*/
  overflow: overlay;/*Progressive enhancement para Opera*/
  scrollbar-gutter: stable;
}
div#app::-webkit-scrollbar {
  width: 3px;
}
/*Webkit(Chrome, Android browser, Safari, Edge...)*/
div#app::-webkit-scrollbar {
  display: none; /*Para que scrollbar-gutter funcione la barra de scroll debe existir*/
  background-color: transparent;
}
div#app:hover::-webkit-scrollbar {
  display: initial;
}
/*Ahora para esconderlo deberemos cambiar el color del manejador a transparente*/
div#app::-webkit-scrollbar-thumb {
  background-color: transparent;
}
div#app:hover::-webkit-scrollbar-thumb {
  background-color: #ffffff80 ;
}

div#app.preloader.active {
  display: none;
}

.preloader {
  display: none;
  position: absolute;
  top: 0px; width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000;
}
.preloader.active {
  display: flex;
}
.preloader img{
  width: 70px;
  height: 70px;
  margin: auto;
}

.t21 {
  font-family: 'Inter-Light';
  font-size: 14px;
  line-height: 20px;
}

.t22 {
  font-family: 'Inter-Bold';
  font-size: 14px;
  line-height: 20px;
}

.text-color-primary {
  color: #ffd233 !important;
}

.center-vh {
  justify-content: center;
  display: flex;
  align-items: center;
}

.center-v {
  display: flex;
  align-items: center !important;
}

.center-h {
  display: flex;
  justify-content: center !important;
}

.btn-primary,
.btn-border,
.btn-secondary {
  font-family: 'Inter-ExtraBold' !important;
  size: 12px !important;
  line-height: 24px !important;
}

.btn-primary {
  background-color: #ffd233 !important;
  border-color: #ffd233 !important;
  color: #131313 !important;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem #ffd2333b !important;
}

.btn-border {
  border: none !important;
  outline: none;
  position: relative;
  border-radius: 5px !important;
  color: #FFD233 !important;
  background: linear-gradient(180deg, #FFDA54 0%, #FAE9AF 100%);
  z-index: 1;
}

.btn-border:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: #070707;
  border-radius: 5px;
  z-index: -1;
}

.btn-secondary {
  border: none !important;
  outline: none;
  position: relative;
  border-radius: 5px !important;
  color: #131313 !important;
  background: linear-gradient(87.21deg, #FFD954 14.06%, #FAEAB1 100%);
}

.navbar-nav .text-primary .nav-link {
  font-family: 'Inter-Medium';
  line-height: 24px;
  size: 24px;
  color: #ffffff !important;
}

.navbar-nav .text-primary a.nav-link:hover {
  color: #ffd233;
}
/* modal buy*/
.modal-buy .modal-content,
.modal-notification .modal-content {
  border-radius: 20px;
  border: 0px solid;
}
.modal-buy .modal-header,
.modal-buy .modal-body,
.modal-buy .modal-footer {
  background-color: #4d4d4d66 !important;
}
.modal-buy .modal-header,
.modal-notification .modal-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-buy .modal-footer,
.modal-notification .modal-footer {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.modal-buy .modal-header img {
  width: 25px;
}
.modal-buy .modal-header,
.modal-notification .modal-header{
  border-bottom: 0px !important;
}
.modal-buy .modal-footer,
.modal-notification .modal-footer {
  border-top: 0px !important;
}

.modal-buy .modal-footer button,
.modal-notification .modal-footer button{
  width: 98px;
}

.modal-buy .btn-secondary{
  background: none !important;
  background-color: #fff0a4 !important;
}

.modal-buy form .form-control {
  height: 49px;
  background-color: #393939;
  border-width: 0px;
}

.modal-buy form .form-control:disabled {
  opacity: 0.5;
}

.modal-buy form .form-control.is-valid,
.modal-buy form .form-control.is-valid:focus,
.modal-buy form .form-control.is-invalid {
  /*base*/
  background-image: none !important;
  padding-right: 20px !important;
  border-width: 1px;
}
.modal-buy form .form-control.is-valid {
  border: 0px !important;
}
.modal-buy form .form-control:focus {
  background-color: #393939 !important;
  color: #FFFFFF;
  box-shadow: 0 0 0 0.2rem #0000000d!important;
}

.modal-buy form input {
  padding: 2px 20px 2px 20px !important;
  border-radius: 5px !important;

  font-family: 'Inter-Light';
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.modal-buy form input:-webkit-autofill,
.modal-buy form input:-webkit-autofill:hover,
.modal-buy form input:-webkit-autofill:focus {
  -webkit-text-fill-color: #FFFFFF;
  box-shadow: 0 0 0px 40px #393939 inset !important;
}

.modal-buy .form-buy-global-pass .input-group.input-dropdown,
.modal-buy .form-buy-global-pass .input-group.input-dropdown input:disabled{
    padding: 1px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #333232;
}

button.dropdown-vue {
  color: #ffff !important;
  border: 0px;
  background-color: transparent !important;
}
button.dropdown-vue:hover,
button.dropdown-vue:focus {
  box-shadow: none !important;
}

ul.dropdown-vue a.dropdown-item:hover,
ul.dropdown-vue a.dropdown-item:focus {
  background-color: #33323261;
}

ul.dropdown-vue a.dropdown-item.active,
ul.dropdown-vue a.dropdown-item:active {
  background-color: #333232 !important;
}

.bg-1{
  background-color: #393939;
}
.bg-2 {
  background-color: #4A4A4A !important;
}
.bg-3 {
  background-color: #505050 !important;
}
.bg-4 {
  background-color: #000000 !important;
}
.bg-5 {
  background-color: #4d4d4d66 !important;
}

/* Modal confirmacion */
#popup.active {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #fff;
}
</style>
