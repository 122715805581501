<template>
    <svg :width="width" :height="height" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 38C29.527 38 38 29.527 38 19C38 8.47297 29.527 0 19 0C8.47297 0 0 8.47297 0 19C0 29.527 8.47297 38 19 38Z" fill="#2775CA"/>
        <path d="M24.2635 21.9527C24.2635 19.1284 22.5946 18.2297 19.2568 17.8446C16.8176 17.5878 16.4324 16.9459 16.4324 15.7905C16.4324 14.6351 17.2027 13.9932 18.8716 13.9932C20.2838 13.9932 21.0541 14.5068 21.4392 15.6622C21.5676 15.9189 21.6959 16.0473 21.9527 16.0473H23.2365C23.4932 16.0473 23.75 15.7905 23.75 15.5338V15.4054C23.4932 13.6081 21.9527 12.3243 20.1554 12.1959V10.2703C20.1554 10.0135 19.8987 9.75675 19.5135 9.62837H18.3581C18.1014 9.62837 17.8446 9.88513 17.7162 10.2703V12.0676C15.277 12.3243 13.8649 13.9932 13.8649 15.9189C13.8649 18.4865 15.4054 19.5135 18.7432 19.8986C20.9257 20.2838 21.6959 20.7973 21.6959 22.0811C21.6959 23.3649 20.5405 24.2635 19.1284 24.2635C17.0743 24.2635 16.3041 23.3649 16.1757 22.2095C16.0473 21.9527 15.9189 21.6959 15.6622 21.6959H14.3784C14.1216 21.6959 13.8649 21.9527 13.8649 22.2095V22.3378C14.1216 24.2635 15.4054 25.804 18.1014 26.1892V28.1149C18.1014 28.3716 18.3581 28.6284 18.7432 28.7568H19.8987C20.1554 28.7568 20.4122 28.5 20.5405 28.1149V26.1892C22.5946 25.804 24.2635 24.1351 24.2635 21.9527Z" fill="white"/>
        <path d="M15.0203 30.2973C8.85813 28.1149 5.64867 21.1824 7.95948 15.1486C9.11489 11.8108 11.8108 9.24324 15.0203 8.08783C15.277 7.95945 15.4054 7.7027 15.4054 7.31756V6.16216C15.4054 5.9054 15.2771 5.64864 14.8919 5.52026C14.7635 5.52026 14.6352 5.52026 14.6352 5.64864C7.06083 8.08783 2.95273 16.0473 5.39192 23.4932C6.80408 27.9865 10.2703 31.3243 14.6352 32.7365C14.8919 32.8649 15.277 32.7365 15.4054 32.4797C15.4054 32.3513 15.4054 32.3513 15.4054 32.0946V30.9392C15.4054 30.8108 15.1487 30.4257 15.0203 30.2973ZM23.3649 5.64864C23.1081 5.52026 22.723 5.64864 22.5946 5.9054C22.4662 6.03378 22.4662 6.03378 22.4662 6.16216V7.31756C22.4662 7.57432 22.723 7.95945 22.9798 8.08783C29.1419 10.2703 32.3514 17.2027 30.0406 23.2365C28.8852 26.5743 26.1892 29.1419 22.9798 30.2973C22.723 30.4257 22.4662 30.6824 22.4662 31.0676V32.223C22.4662 32.4797 22.5946 32.7365 22.9798 32.8649C23.1081 32.8649 23.2365 32.8649 23.2365 32.7365C30.8108 30.2973 34.9189 22.3378 32.4798 14.8919C31.196 10.3986 27.7298 7.0608 23.3649 5.64864Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        props: {
            fill: {
                type: String,
                default: '#FFFFFF'
            },
            width: {
                type: Number,
                default: 79
            },
            height: {
                type: Number,
                default: 79
            }
        }
    }
</script>
