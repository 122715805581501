<template>
    <svg :width="width" :height="height" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 0C29.4766 0 38 8.52336 38 19C38 29.4766 29.4766 38 19 38C8.52336 38 0 29.4766 0 19C0 8.52336 8.52336 0 19 0Z" fill="#53AE94"/>
        <path d="M21.3676 16.4548V13.6137H27.8193V9.29285H10.1807V13.6137H16.6324V16.4548C11.3645 16.6916 7.45795 17.757 7.45795 19C7.45795 20.243 11.4237 21.3084 16.6324 21.5452V30.6013H21.3084V21.5452C26.5763 21.3084 30.4829 20.243 30.4829 19C30.4829 17.757 26.5763 16.6916 21.3676 16.4548ZM21.3676 20.7757C21.2492 20.7757 20.5389 20.8349 19.0592 20.8349C17.8754 20.8349 16.9875 20.7757 16.6916 20.7757C12.0748 20.5981 8.58256 19.7695 8.58256 18.8224C8.58256 17.8754 12.0748 17.0467 16.6916 16.8692V20.0062C16.9875 20.0062 17.8754 20.0654 19.0592 20.0654C20.4798 20.0654 21.2492 20.0062 21.3676 20.0062V16.8692C25.9844 17.0467 29.4766 17.8754 29.4766 18.8224C29.4766 19.7695 25.9844 20.539 21.3676 20.7757Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        props: {
            fill: {
                type: String,
                default: '#FFFFFF'
            },
            width: {
                type: Number,
                default: 38
            },
            height: {
                type: Number,
                default: 38
            }
        }
    }
</script>
