<template>
  <b-dropdown
    variant="primary"
    toggle-class="dropdown-vue pr-3 d-flex align-items-center"
    menu-class="dropdown-vue bg-2"
    >
    <template #button-content>
      {{!!selected ?selected.symbol :""}}
      <IconComponent :data="selected"/>
    </template>
    <b-dropdown-item
      v-for="(option, index) in items" v-bind:key="index"
      @click="selectCoin(option)"
      :active="isActive (option)"
      :class="[option.disabled ?'disabled': '']"
    >
      <div class="h-100 d-flex">
        <IconComponent :data="option"/>
        <div class="ml-2 text-white">
          <h5 class="t19 mb-0">{{ option.symbol }}</h5>
          <h6 class="t20 mb-0">{{ option.name }}</h6>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script >
import IconComponent from '@/components/IconComponent.vue'
export default {
    props: [
      'items',
      'selected'
    ],
    components: {
      IconComponent,
    },
    async created() {},
    data() { return {} },
    methods: {
      isActive (option) {
        if (this.selected) {
          return option.symbol === this.selected.symbol
        }
        return false
      },
      selectCoin(option) {
        this.$emit('click', option.disabled ? null :option)
      }
    }
  }
</script>
<style scoped>

.dropdown-menu li.disabled {
  background-color: #4A4A4A;
  opacity: 0.2;
}
.swap .dropdown-menu li.disabled:hover {
  opacity: 1;
}

</style>