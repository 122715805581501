/**/
const redes = {
  trongrid: {
    fullNode: 'https://api.trongrid.io',
    solidityNode: 'https://api.trongrid.io',
    eventServer: 'https://api.trongrid.io',
  },
  shasta: {
    fullNode: 'https://api.shasta.trongrid.io',
    solidityNode: 'https://api.shasta.trongrid.io',
    eventServer: 'https://api.shasta.trongrid.io',
  },
  nile: {
    fullNode: 'https://nile.trongrid.io',
    solidityNode: 'https://nile.trongrid.io',
    eventServer: 'https://nile.trongrid.io',
  },
  local: {
    fullNode: 'http://127.0.0.1:9090',
    solidityNode: 'http://127.0.0.1:9090',
    eventServer: 'http://127.0.0.1:9090',
  },
}

const env = {
  production: {
    red: 'trongrid',
  },
  development: {
    red: 'nile',
  },
  preDev: {
    red: 'nile',
  },
} /**/
const statusPluginOptions = {
  installing: 0,
  installed: 1,
  notInstalled: 2,
}

export const wallet = {
  namespaced: true,
  state: {
    tronWeb: null,
    tronGrid: null,
    plugin: false,
    onlyReader: true,
    sync: false,
    red: null,
    status: statusPluginOptions.notInstalled,
    statusPluginOptions: statusPluginOptions,
  },
  mutations: {
    reset(state) {
      state.sync = false
      state.plugin = false
      state.tronWeb = null
      state.onlyReader = true
      state.red = env[process.env.NODE_ENV].red
    },
    setPlugin(state, value) {
      state.plugin = value
    },
    setSync(state, value) {
      state.sync = value
    },
    setTronWeb(state, value) {
      state.tronWeb = value
    },
    setOnlyReader(state, value) {
      state.onlyReader = value
    },
    /**/
    setTronWebDefault(state) {
      /**/
      const TronWeb = require('tronweb')
      const TronGrid = require('trongrid')

      state.red = redes[env[process.env.NODE_ENV].red]

      const HttpProvider = TronWeb.providers.HttpProvider

      console.log({ red: state.red })

      state.tronWeb = new TronWeb(
        new HttpProvider(state.red.fullNode),
        new HttpProvider(state.red.solidityNode),
        new HttpProvider(state.red.eventServer),
        ''
      )
      state.tronWeb.setAddress('TTSrcwTx2kpKw51SKL58YzYVHo9Kg8D2JS')
      state.tronGrid = new TronGrid(state.tronWeb)
      /**/
      console.log({ tronWeb: state.tronWeb })
    },
    setStatus(state, value) {
      state.status = value
    },
  },
  actions: {
    /***
      conectar con la billetera utilizando tronWeb
    ***/
    async connectWallet({ state, commit }) {
      let count = 1
      let plugin = false

      commit('reset')
      commit('setStatus', state.statusPluginOptions.installing)

      return new Promise((resolve) => {
        const ciclo = setInterval(() => {
          plugin = !!window.tronWeb && window.tronWeb.ready
          if (plugin) {
            console.log('plugin installed')
            commit('setPlugin', true)
            commit('setStatus', state.statusPluginOptions.installed)
          } else if (count === 3) {
            commit('setTronWebDefault')
            commit('setStatus', state.statusPluginOptions.installed)
            console.log('no fue posible conectar con tronWeb')
          }
          if (plugin || count === 3) {
            commit('setSync', true)
            console.log({ count })
            clearInterval(ciclo)
            resolve()
          }
          console.log('count' + count)
          count++
        }, 5000)
      })
    },
  },
  getters: {
    getTronWeb: (state) => (state.plugin ? window.tronWeb : state.tronWeb),
    getStatusWallet: (state) => state.status,
    getStatusPluginOptions: (state) => state.statusPluginOptions,
    isLoggedIn: (state) => state.plugin ? window.tronWeb.ready : false,
    getAddress: function (state) {
      const tronWeb = state.plugin ? window.tronWeb : state.tronWeb
      return tronWeb ? tronWeb.defaultAddress.base58 : ''
    },
    getRed: (state) => state.red,
  },
}
